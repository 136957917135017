export function validate(el, errors) {
  const outcome = el.validate();
  if (!errors.length) el.focus();
  if (!outcome) errors.push(el.$attrs.name);
}

export function isNullish(value) {
  return value === undefined || value === null;
}

export default {
  validate,
};
