import {
  Article,
  MagazineArticle,
  Mention,
  MentionType,
  PaperArticle,
  PodcastEpisode,
  RadioClip,
  RadioClipSyndication,
  Syndication,
  TvCaption,
  TvCaptionSyndication,
  TvClip,
  TvLogoAppearance,
} from "shared/types/mentions";

export function isTvCaption(mention: Mention): mention is TvCaption {
  return mention.type === MentionType.tv_caption;
}

export function isPaperArticle(mention: Mention): mention is PaperArticle {
  return mention.type === MentionType.paper_article;
}

export function isMagazineArticle(
  mention: Mention
): mention is MagazineArticle {
  return mention.type === MentionType.magazine_article;
}

export function isMentionWithTvEyes(
  mention: Mention
): mention is TvCaption | PodcastEpisode {
  return "tveyes_player_url" in mention;
}

export function isMentionWithAppRedirect(
  mention: Mention
): mention is Article | PaperArticle | MagazineArticle {
  return "in_app_redirect" in mention;
}

export function isMentionWithBody(
  mention: Mention
): mention is Article | PaperArticle {
  return "body" in mention || "translated_body" in mention;
}

export function isMentionWithDatahub(
  mention: Mention
): mention is PaperArticle | RadioClip | TvCaption {
  return "datahub" in mention;
}

export function isMentionRequiresAllowanceCheck(
  mention: Mention
): mention is PaperArticle | MagazineArticle {
  return "requires_allowance_check" in mention;
}

export function isMentionWithSyndications(
  mention: Mention
): mention is Exclude<
  Mention,
  MagazineArticle | TvClip | TvLogoAppearance | PodcastEpisode
> {
  return "syndications" in mention;
}

export function isMentionWithRelatedArticle(
  mention: Mention
): mention is Mention & {
  related_article: Article | PaperArticle;
} {
  return "related_article" in mention && Boolean(mention.related_article);
}

export function isMentionFinancialTimes(mention: Mention) {
  return (
    ("license" in mention && mention.license === "financial-times") ||
    mention.source?.name === "Financial Times"
  );
}

export function isTvOrRadioSyndication(
  syndication: Syndication
): syndication is TvCaptionSyndication | RadioClipSyndication {
  return (
    syndication.type === "radio_clip" ||
    ["tv_super", "tv_caption", "tv_logo_appearance"].includes(syndication.type)
  );
}
