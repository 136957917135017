<template>
  <InputSelect
    v-model="selectedGroupId"
    :options="filteredListOptions"
    option-prop="id"
    option-label="label"
    :placeholder="$t('streams_by_group_picker.pick_a_group')"
    emit-value
    use-input
    new-design
    @filter="filterListOptions"
  >
    <template #prepend>
      <div
        v-if="selectedOption"
        class="stream-icon"
        :style="{ backgroundColor: selectedOption.color }"
      />
      <div v-else>
        <ImageIcon
          fallback-icon="ion-search"
          class="q-pl-sm text-ds-denim-6"
        />
      </div>
    </template>
    <template #option="{ option, selected, disabled, active }">
      <div
        :class="{
          active: active,
          selected: selected,
          group: option.group,
        }"
        class="list-option flex justify-between cursor-pointer"
      >
        <slot
          v-if="disabled"
          name="disabled"
        />
        <div class="col">
          <div class="row items-center no-wrap">
            <QIcon
              v-if="option.icon"
              :name="option.icon"
              size="12px"
              class="icon"
            />
            <div
              v-if="!option.icon"
              class="stream-icon"
              :style="{ backgroundColor: option.color }"
            />
            <div class="text-label overflow-hidden ellipsis q-pl-sm">
              {{ option.label }}
            </div>
            <QIcon
              v-if="selected"
              name="done"
              size="16px"
              class="icon"
              color="ds-denim-9"
            />
          </div>
        </div>
      </div>
    </template>
  </InputSelect>
</template>

<script>
import { storeToRefs } from "pinia";

import { ImageIcon, InputSelect } from "shared/components/base";
import { useStreamsStore } from "shared/stores/streams";

export default {
  name: "StreamsByGroupPicker",
  components: {
    InputSelect,
    ImageIcon,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    selectedGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue", "update:selected-group"],
  setup() {
    const streamsStore = useStreamsStore();
    const { groupedTraditionalStreams } = storeToRefs(streamsStore);

    return {
      groupedTraditionalStreams,
    };
  },
  data() {
    return {
      selectedGroupId: null,
      filteredListOptions: [],
    };
  },
  computed: {
    options() {
      return this.groupedTraditionalStreams().map((group) => ({
        ...group,
        id: group.id || group.label,
        label: `${group.label} (${group.streams.length})`,
      }));
    },
    selectedOption() {
      if (this.selectedGroupId) {
        return this.options.find(
          (option) => option.id === this.selectedGroupId
        );
      }

      return null;
    },
  },
  watch: {
    selectedGroup: {
      immediate: true,
      handler() {
        if (this.selectedGroup?.id) {
          this.selectedGroupId = this.selectedGroup.id;
        } else {
          this.selectedGroupId = this.$t(
            "streams_by_group_picker.primary_mention_stream"
          );
        }
      },
    },
    selectedGroupId: {
      immediate: false,
      handler: "updateSelectedGroup",
    },
  },
  mounted() {
    this.filteredListOptions = this.options;
    this.updateSelectedGroup();
  },
  methods: {
    updateSelectedGroup() {
      const group = this.options.find(
        (option) => option.id === this.selectedGroupId
      );
      this.$emit("update:modelValue", group.streams);
      this.$emit("update:selected-group", group);
    },
    filterListOptions(value) {
      const filter = value.toLowerCase();
      this.filteredListOptions = this.options.filter(({ label }) =>
        label.toLowerCase().includes(filter)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.stream-group-label {
  max-width: 180px;
}

.filter-container {
  border-bottom: 1px solid #ddd;
}

input {
  border: 0;
  font-size: 16px;
  background: #fff;
}

.flex-1 {
  flex: 1;
}

.font-normal {
  font-weight: normal;
}

.search-input-container {
  background: #fff;
  height: 32px;
  border-radius: 2px;
}

.search-icon {
  color: #9b9b9b;
}

.close-icon {
  &:hover {
    color: #444;
  }
}

.node {
  &.parent {
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }
  }

  &.child {
    width: 100%;
  }
}

.desktop {
  .filter-container {
    border-bottom: none;
  }

  .node {
    &.parent {
      border-bottom: none;
    }
  }
}

.mobile {
  .streams-container {
    height: 550px;
    max-height: 550px;
  }
}

.stream-icon {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  min-width: 12px;
  background-color: #757575;
  border: 1px solid $secondary;
}

.text-label {
  width: 230px;
  font-size: 13px;
  color: $ds-denim-9;
  flex: 1;
}
</style>
