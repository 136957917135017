import { storeToRefs } from "pinia";

import loadScript from "shared/services/script-loader";
import StorageService from "shared/services/StorageService";
import { useGlobalStore } from "shared/stores/global";

function mountChurnZero(srcUrl) {
  if (window.ChurnZero) {
    try {
      window.ChurnZero.push(["stop"]);
    } finally {
      delete window.ChurnZero;
    }
  }

  const script = document.querySelector(
    'script[src*="churnzero.net/churnzero.js"]'
  );
  if (script) script.parentNode.removeChild(script);

  return loadScript(srcUrl);
}

function identifyUser(churnZeroAttributes = {}) {
  const {
    global_customer_id: globalCustomerId,
    organisation_name: organisationName,
    first_name: firstName,
    last_name: lastName,
    email,
    ...rest
  } = churnZeroAttributes;

  window.ChurnZero.push(["setContact", globalCustomerId.toString(), email]);
  window.ChurnZero.push(["setAttribute", "Account", "Name", organisationName]);
  window.ChurnZero.push([
    "setAttribute",
    "Contact",
    {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      ...rest,
    },
  ]);
}

function setChurnZeroAuthToken(token) {
  window.ChurnZero.push([
    "setAuth",
    () => ({
      auth_token: token,
    }),
  ]);
}

export default async function initChurnZero(churnZeroAttributes) {
  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const { churnzero } = appConfig.value;

  const churnZeroScriptUrl = churnzero.scriptUrl;
  let churnZeroAppKey = churnzero.appKey;

  if (
    churnZeroAppKey === "" ||
    !/^production_[a-z]{2}$/.test(import.meta.env.VITE_ENV)
  ) {
    churnZeroAppKey = null;
  }

  if (churnZeroAppKey && churnZeroScriptUrl) {
    await mountChurnZero(churnZeroScriptUrl);
    window.ChurnZero.push(["setAppKey", churnZeroAppKey]);
    identifyUser(churnZeroAttributes);

    const token = await StorageService.get("token");
    setChurnZeroAuthToken(token);
  }
}
