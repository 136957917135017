import { storeToRefs } from "pinia";
import { computed, ref, toRefs } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { toSentence } from "shared/helpers/array";
import { getMediaForStream } from "shared/helpers/media";
import StreamFilters from "shared/helpers/StreamFilters";
import { useStreamsStore } from "shared/stores/streams";

export default function streamFiltering(options = {}) {
  const { stream } = toRefs(options);
  let { filters } = toRefs(options);
  const { getStreamFilters } = storeToRefs(useStreamsStore());

  const streamFilters = getStreamFilters.value(stream?.value?.id);

  if (streamFilters) {
    // eslint-disable-next-line vue/no-ref-as-operand
    filters = ref(streamFilters);
  } else if (!filters?.value) {
    // eslint-disable-next-line vue/no-ref-as-operand
    filters = ref(new StreamFilters(stream?.value));
  }

  const selectorActive = ref(null);

  const filtering = computed(() =>
    Boolean(
      filters.value.filteringContent() ||
        filters.value.filteringReactScore() ||
        filters.value.filteringLocations() ||
        filters.value.filteringMedia(getMediaForStream(stream.value)) ||
        filters.value.filteringPlatforms() ||
        filters.value.filteringHandleOrSocialImpact() ||
        filters.value.filteringSources() ||
        filters.value.filteringTags()
    )
  );

  const filterLabels = computed(() => {
    const labels = [];

    if (filters.value.filteringAuthors()) {
      let label = getLocaleText("stream_filtering.author");

      if (stream.value.type === streamTypes.socialStream) {
        label += getLocaleText("stream_filtering.handle");
      }

      labels.push(label);
    }

    if (filters.value.filteringContent()) {
      labels.push(getLocaleText("stream_filtering.content"));
    }

    if (filters.value.filteringReactScore()) {
      labels.push(getLocaleText("stream_filtering.react_score"));
    }

    if (filters.value.filteringLocations()) {
      labels.push(getLocaleText("stream_filtering.locations"));
    }

    if (filters.value.filteringMedia(getMediaForStream(stream.value))) {
      labels.push(getLocaleText("stream_filtering.media"));
    }

    if (filters.value.filteringPlatforms()) {
      labels.push(getLocaleText("stream_filtering.social_platforms"));
    }

    if (filters.value.filteringSources()) {
      labels.push(getLocaleText("stream_filtering.sources"));
    }

    if (filters.value.filteringTags()) {
      labels.push(getLocaleText("stream_filtering.tags"));
    }

    return labels;
  });

  const filteringLabel = computed(() =>
    getLocaleText("stream_filtering.filtering_by", {
      category: toSentence(filterLabels.value),
    })
  );

  const filtersSelectorActive = computed({
    get() {
      return selectorActive.value === "filters";
    },
    set(value) {
      if (!value) selectorActive.value = null;
      selectorActive.value = "filters";
    },
  });

  const displayFiltersSelector = () => {
    selectorActive.value = "filters";
  };

  const toggleDisplayFiltersSelector = () => {
    selectorActive.value = filtersSelectorActive.value ? null : "filters";
  };

  return {
    filters,
    filtering,
    filterLabels,
    filteringLabel,
    selectorActive,
    filtersSelectorActive,
    displayFiltersSelector,
    toggleDisplayFiltersSelector,
  };
}
