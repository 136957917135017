<template>
  <Transition name="fade">
    <div
      v-show="!searchHidden && !$modals.hasModalOpen()"
      :class="{ visible: searchOpen }"
      class="universal-search-wrapper"
    >
      <div
        v-if="searchOpen"
        :class="{
          active: $isDesktop && showResults,
        }"
        class="absolute search"
      >
        <div
          class="search-bar"
          :class="{ active: showResults }"
        >
          <UniversalSearchInput
            ref="input"
            v-model="searchOptions.dummyStream.keywords"
            v-model:scope="searchOptions.searchScope"
            @search="debouncedOnSearch"
            @select="onSelect"
            @close="close"
            @clear="reset"
          />
        </div>

        <div
          v-if="showResults"
          class="results row"
        >
          <div
            class="q-px-md q-py-sm"
            :class="[
              $isDesktop && 'col-8 column scroll-y full-height no-wrap',
              $isMobile && 'bg-body search-results-container',
              $q.platform.is.ios && 'full-height',
            ]"
          >
            <div
              v-if="$isMobile"
              class="q-mb-sm items-center controls-container"
            >
              <UniversalSearchActionsheetFilters
                v-model:stream="searchOptions.dummyStream"
                v-model:range="searchOptions.range"
                v-model:streams="searchOptions.streams"
                v-model:sources="searchOptions.sources"
                :excluded-media="excludedMedia"
                :search-scope="searchOptions.searchScope"
                @search="debouncedOnSearch"
              />
            </div>

            <UniversalSearchJournalistsList
              v-if="searchOptions.searchScope === 'journalists'"
              ref="journalistsList"
              :stream="searchOptions.dummyStream"
              :default-range="searchOptions.range"
              :fetching-options="fetchingOptions"
              class="scroll-y stream"
              @journalist-clicked="filtersHidden = true"
              @preview-closed="filtersHidden = false"
            >
              <template #no-journalists>
                <div
                  class="no-media-container column justify-center items-center"
                >
                  <i18n-t
                    scope="global"
                    tag="strong"
                    keypath="universal_search.no_journalists_try_list"
                    class="q-mb-md text-center"
                  >
                    <template #lineBreak><br /></template>
                  </i18n-t>
                </div>
              </template>
            </UniversalSearchJournalistsList>

            <MentionStream
              v-else
              ref="mentionStream"
              :stream="searchOptions.dummyStream"
              :fetching-service="fetchingService"
              :default-range="searchOptions.range"
              :fetching-options="fetchingOptions"
              class="scroll-y stream"
              paginate
              hide-button-bar
              hide-edit-button
              hide-filters-selector
              hide-generate-report-button
              hide-range-selector
              hide-header
              hide-notification-watcher
              hide-stream-visualisation-button
              no-highlighting
            >
              <template #noMentions>
                <div
                  class="no-media-container column justify-center items-center"
                >
                  <i18n-t
                    scope="global"
                    tag="strong"
                    keypath="universal_search.no_coverage_try_list"
                    class="q-mb-md text-center"
                  >
                    <template #lineBreak><br /></template>
                  </i18n-t>
                </div>
              </template>
            </MentionStream>
          </div>

          <div
            v-if="$isDesktop && !filtersHidden"
            class="col-4 filters-container full-height scroll-y"
          >
            <div class="flex justify-between q-px-md q-py-sm">
              <span class="title bold">{{
                $t("universal_search.filter_options")
              }}</span>

              <QBtn
                :label="$t('universal_search.reset')"
                size="12px"
                style="font-weight: normal"
                dense
                flat
                @click="resetFilters"
              />
            </div>
            <div
              v-if="searchOptions.searchScope === 'streams'"
              class="q-pa-md"
            >
              <span class="bold row text-16">{{
                $t("universal_search.mention_streams_group")
              }}</span>
              <StreamsByGroupPicker
                v-model="searchOptions.streams"
                v-model:selected-group="selectedGroup"
                @update:model-value="debouncedOnSearch"
              />
            </div>

            <div
              v-if="hasSearchFilters"
              class="q-pa-md"
            >
              <span class="bold block text-16">{{
                $t("universal_search.date_range")
              }}</span>
              <div class="flex items-center">
                <QBtn
                  flat
                  dense
                  icon="navigate_before"
                  @click="previousDay()"
                />
                <InputDatePicker
                  v-model:range="searchOptions.range"
                  range-selection="custom"
                  close-on-update
                  show-date-input
                  show-time-input
                  show-range-options
                  show-update-button
                  limit-to-past-year
                  date-style="long"
                  :fallback-placements="['left']"
                  @update:range="debouncedOnSearch"
                >
                  <template #btn="{ toggle, afterLabel, beforeLabel }">
                    <div
                      class="pointer flex justify-center items-center bigger"
                      @click="toggle"
                    >
                      <QIcon
                        name="fas fa-calendar"
                        size="14px"
                        class="q-mr-xs"
                      />
                      {{
                        $t("universal_search.after_to_before", {
                          afterLabel,
                          beforeLabel,
                        })
                      }}
                    </div>
                  </template>
                </InputDatePicker>
                <QBtn
                  flat
                  dense
                  icon="navigate_next"
                  @click="nextDay()"
                />
              </div>
            </div>

            <div
              v-if="$isDesktop"
              class="q-pa-md"
            >
              <span class="bold block text-16">{{
                $t("universal_search.media_types")
              }}</span>
              <MediumSelector
                v-model="searchOptions.dummyStream"
                :excluded-media="excludedMedia"
                inline
                @update:model-value="debouncedOnSearch"
              />
            </div>
            <div
              v-if="$isDesktop"
              class="q-pa-md"
            >
              <span class="q-pb-sm bold block text-16">{{
                $t("universal_search.sources")
              }}</span>
              <FiltersAutocomplete
                v-model="searchOptions.sources"
                keep-expanded
                :stream="searchOptions.dummyStream"
                :media="searchEnabledMedia"
                filter-type="sources"
                @update:model-value="debouncedOnSearch"
              />
            </div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div
          v-if="searchOpen && !searchHidden"
          class="backdrop absolute-full"
          @click="close"
        />
      </Transition>
    </div>
  </Transition>
</template>

<script>
import { debounce } from "lodash-es";
import { storeToRefs } from "pinia";

import { track } from "shared/boot/analytics";
import InputDatePicker from "shared/components/base/InputDatePicker.vue";
import FiltersAutocomplete from "shared/components/core/forms/FiltersAutocomplete.vue";
import StreamsByGroupPicker from "shared/components/core/pickers/StreamsByGroupPicker.vue";
import MentionStream from "shared/components/MentionStream.vue";
import UniversalSearchActionsheetFilters from "shared/components/search/UniversalSearchActionsheetFilters.vue";
import UniversalSearchInput from "shared/components/search/UniversalSearchInput.vue";
import UniversalSearchJournalistsList from "shared/components/search/UniversalSearchJournalistsList.vue";
import MediumSelector from "shared/components/selectors/MediumSelector.vue";
import useContentWarning from "shared/composables/useContentWarning";
import {
  addTime,
  subtractTime,
  toMilliseconds,
  unixDateTime,
} from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { getMediaForStream, getSources } from "shared/helpers/media";
import { sourceGroupKeys, sourceKeysFor } from "shared/helpers/sourceFilters";
import fetchingService from "shared/services/fetching/streamSearch";
import { useStreamsStore } from "shared/stores/streams";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

export default {
  name: "UniversalSearch",
  components: {
    InputDatePicker,
    MentionStream,
    FiltersAutocomplete,
    UniversalSearchActionsheetFilters,
    UniversalSearchInput,
    UniversalSearchJournalistsList,
    MediumSelector,
    StreamsByGroupPicker,
  },
  setup() {
    const { openSearchCoverageContentWarningModal } = useContentWarning();

    const streamsStore = useStreamsStore();
    const {
      mentionStreams,
      socialStreams,
      groupedTraditionalStreams,
      primaryStreams,
    } = storeToRefs(streamsStore);

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen, searchQuery, searchScope } =
      storeToRefs(universalSearchStore);

    const { hideSearch, setSearchQuery, setSearchScope } = universalSearchStore;

    const userStore = useUserStore();
    const { adminUserEnabled, isLoggedIn } = storeToRefs(userStore);

    return {
      openSearchCoverageContentWarningModal,
      mentionStreams,
      socialStreams,
      groupedTraditionalStreams,
      primaryStreams,

      hideSearch,
      setSearchQuery,
      setSearchScope,
      searchOpen,
      searchQuery,
      searchScope,

      adminUserEnabled,
      isLoggedIn,
    };
  },
  data() {
    return {
      fetchingService,
      sources: getSources(),
      searchOptions: {
        searchScope: "streams",
        streams: [],
        sources: [],
        range: DateRange.today(),
        dummyStream: {
          skipReload: true, // so watcher in MentionStream.vue doesn't trigger redundant refresh
          keywords: "",
        },
      },
      defaultDateRange: DateRange.today(),
      isSearching: false,
      savedSearch: {},
      debouncedOnSearch: debounce(this.onSearch, 400),
      searchHidden: false,
      filtersHidden: false,
      selectedGroup: null,
    };
  },
  computed: {
    storedSearchScope: {
      get() {
        return this.searchScope;
      },
      set(value) {
        this.setSearchScope(value);
      },
    },
    storedSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(value) {
        this.setSearchQuery(value);
      },
    },
    showResults() {
      return this.searchOptions.dummyStream.keywords.length && this.isSearching;
    },
    hasSearchFilters() {
      return ["streams", "all"].includes(this.searchOptions.searchScope);
    },
    canSearchAvailableContent() {
      if (this.adminUserEnabled) return true;

      return (
        this.$features.has("has_search_available_content") &&
        this.$permissions.has("can_search_available_content")
      );
    },
    selectedStreamIds() {
      return this.searchOptions.streams.map((stream) => stream.id);
    },
    selectedContentTypes() {
      return this.sources.reduce(
        (acc, source) => ({
          ...acc,
          [`${source.field}_content`]:
            this.searchOptions.dummyStream[`${source.field}_content`],
        }),
        {}
      );
    },
    excludedMedia() {
      // No social for Journalists
      if (this.searchOptions.searchScope === "journalists") {
        return ["Social"];
      }

      return [];
    },
    searchEnabledMedia() {
      return getMediaForStream(this.searchOptions.dummyStream);
    },
    endpoint() {
      return {
        all: "search",
        streams: "search/streams",
        journalists: "search/journalists",
      }[this.searchOptions.searchScope];
    },
    fetchingOptions() {
      const { selectedStreamIds, endpoint, excludedMedia } = this;
      const { range, sources } = this.searchOptions;

      return {
        stream_ids: selectedStreamIds,
        endpoint,
        range,
        excludedMedia,
        useMilliseconds: true,
        source_group_keys: sourceGroupKeys(sources),
        source_keys: sourceKeysFor(sources),
      };
    },
    shouldResetSavedSearch() {
      return (
        this.savedSearch.query !== this.searchOptions.dummyStream.keywords ||
        this.savedSearch.search_type !== this.searchOptions.searchScope
      );
    },
    streamGroups() {
      return this.groupedTraditionalStreams({ menuGroups: true });
    },
  },
  watch: {
    $route() {
      if (this.$isMobile) {
        this.searchHidden = this.$route.name !== "search";
      } else if (this.searchOpen) {
        this.close();
      }
    },
    isLoggedIn() {
      if (!this.isLoggedIn) {
        this.close();
      }
    },
    async searchOpen(isOpen) {
      if (isOpen) {
        this.init();

        if (this.storedSearchQuery) {
          Object.assign(this.searchOptions.dummyStream, {
            keywords: this.storedSearchQuery,
          });
          this.debouncedOnSearch();
          this.storedSearchQuery = "";
        } else {
          await this.$nextTick();
          this.$refs.input.focus();
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.sources = getSources();
      Object.assign(this.searchOptions, {
        streams: this.primaryStreams,
        searchScope: this.canSearchAvailableContent
          ? "all"
          : this.storedSearchScope,
      });

      this.reset();
    },
    close() {
      this.reset();
      this.hideSearch();

      if (this.$isMobile) {
        this.$router.go(-1);
      }

      this.$track("Closed Universal Search");
    },
    reset() {
      this.filtersHidden = false;
      this.isSearching = false;
      this.searchOptions.dummyStream.keywords = "";
      this.resetFilters();

      this.$track("Reset Universal Search");
    },
    resetFilters() {
      this.storedSearchScope = "streams";

      this.searchOptions = {
        ...this.searchOptions,
        range: this.defaultDateRange,
        streams: this.primaryStreams,
        sources: [],
      };
      this.resetSelectedGroup();

      const stream = { ...this.searchOptions.dummyStream };
      this.sources.forEach((source) => {
        stream[`${source.field}_content`] = true;
      });

      this.searchOptions.dummyStream = stream;

      this.$track("Reset Universal Search Filters");

      this.debouncedOnSearch();
    },
    resetSelectedGroup() {
      this.selectedGroup = {
        ...this.streamGroups.find(
          ({ slug }) => slug === this.$route.params.groupSlug
        ),
      };
    },
    onSelect(search) {
      this.savedSearch = search;

      this.searchOptions.dummyStream = {
        ...this.searchOptions.dummyStream,
        ...this.savedSearch,
        id: null,
        keywords: this.savedSearch.query,
      };

      this.searchOptions = {
        ...this.searchOptions,
        range: new DateRange({
          before: this.savedSearch.before,
          after: this.savedSearch.after,
        }),

        searchScope: this.savedSearch.search_type,

        streams: this.searchOptions.streams,
      };

      this.isSearching = true;
      this.$track("Selected Saved Universal Search", {
        searchId: search.id,
      });
    },
    async onSearch() {
      if (!this.searchOptions.dummyStream.keywords) return;

      track("Ran Universal Search Query", {
        query: this.searchOptions.dummyStream.keywords,
        scope: this.searchOptions.searchScope,
      });

      if (this.shouldResetSavedSearch) this.savedSearch = {};

      if (
        this.savedSearch.search_type === "streams" &&
        this.savedSearch.print_content
      ) {
        await this.openSearchCoverageContentWarningModal();
      }

      this.isSearching = true;
      this.refreshMentions();
    },
    saveSearch(params) {
      const handleErrors = true;

      return this.savedSearch.id
        ? this.$streemApiV1.put(`universal_searches/${this.savedSearch.id}`, {
            params,
            handleErrors,
          })
        : this.$streemApiV1.post("universal_searches", {
            params,
            handleErrors,
          });
    },
    async recordSearch() {
      const params = {
        query: this.searchOptions.dummyStream.keywords,
        stream_ids: this.selectedStreamIds,
        search_type: this.searchOptions.searchScope,
        ...this.searchOptions.range,
        ...this.selectedContentTypes,
      };

      const result = await this.saveSearch(params);
      this.savedSearch = result.data;
    },
    refreshMentions() {
      if (this.$refs.mentionStream) {
        this.$refs.mentionStream.refreshMentions();
      }

      if (this.$refs.journalistsList) {
        this.$refs.journalistsList.refreshJournalists();
      }

      this.recordSearch();
    },
    previousDay() {
      ["before", "after"].forEach((boundary) => {
        this.searchOptions.range[boundary] = unixDateTime(
          subtractTime(
            new Date(toMilliseconds(this.searchOptions.range[boundary])),
            1,
            "day"
          )
        );
      });

      this.debouncedOnSearch();
    },
    nextDay() {
      ["before", "after"].forEach((boundary) => {
        this.searchOptions.range[boundary] = unixDateTime(
          addTime(
            new Date(toMilliseconds(this.searchOptions.range[boundary])),
            1,
            "day"
          )
        );
      });

      this.debouncedOnSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
.autocomplete {
  :deep(.input-container) {
    input {
      background-color: $white;
      border-radius: 4px;
    }
  }
}

.text-16 {
  font-size: 16px;
}

.universal-search-wrapper {
  position: absolute;
  inset: 0;
  z-index: 100;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.backdrop {
  background: rgb(0 0 0 / 60%);
  z-index: 500;
}

.search {
  background: $body-background;
  width: 100%;
  z-index: 1000;
  box-shadow:
    0 2.8px 2.2px rgb(0 0 0 / 3.4%),
    0 6.7px 5.3px rgb(0 0 0 / 4.8%),
    0 12.5px 10px rgb(0 0 0 / 6%),
    0 22.3px 17.9px rgb(0 0 0 / 7.2%),
    0 41.8px 33.4px rgb(0 0 0 / 8.6%),
    0 100px 80px rgb(0 0 0 / 12%);

  .results,
  .search-results-container {
    padding: 8px 4px;
  }
}

.title {
  font-size: 19px;
}

.search-bar {
  background: white;

  &.active {
    border-bottom: 1px solid #eee;
  }
}

.select {
  &::after {
    right: -4px;
    margin-top: 0;
    color: #444;
    border-width: 4px;
  }

  select {
    background: $body-background;
    font-size: 16px;
    padding: 4px;
    width: 110px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.desktop {
  .search {
    max-width: 1000px;
    top: 7vh;
    left: calc((100% - 1000px) / 2);
    border-radius: 4px;
    overflow: hidden;

    &.active {
      height: 86vh;
    }
  }

  .results {
    height: calc(100% - 100px);
  }
}

.mobile {
  .universal-search-wrapper {
    z-index: 3000;
    overflow: hidden;
    margin-top: env(safe-area-inset-top);
  }

  .search {
    height: 100%;
    border-radius: 0;
  }

  .controls-container {
    height: 40px;
  }

  .search-results-container {
    position: initial;
    width: 100%;
  }

  .stream {
    position: fixed;
    inset: calc(125px + env(safe-area-inset-top)) 0 0;
    height: calc(
      100% - 125px - env(safe-area-inset-top) - env(safe-area-inset-bottom)
    ) !important;
  }
}
</style>
