import { cloneDeep } from "lodash-es";

import { streamTypes } from "shared/constants";
import DateRange from "shared/helpers/DateRange";
import { social } from "shared/helpers/media";
import { sourceGroupKeys, sourceKeysFor } from "shared/helpers/sourceFilters";
import { isNullish } from "shared/helpers/validations";
import features from "shared/services/features";

function platformsToMediaTypes(platforms) {
  const mediaTypes = [];

  social.platforms.forEach((platform) => {
    if (platforms.includes(platform.platform)) {
      mediaTypes.push(...platform.fields);
    }
  });

  return mediaTypes;
}

export default class StreamFilters {
  static get DEFAULT_FILTERS() {
    return {
      keywords: "",
      excludedKeywords: "",
      booleanQuery: "",
      sources: [],
      tags: [],
      excludedTags: [],
      excludedSources: [],
      authors: [],
      categories: [],
      locations: [],
      tiers: [],
      sentiment: [{ min: -1, max: 1 }],
      wordCount: {
        min: 0,
        max: 3000,
      },
      onlyShowExternalItems: false,
      range: DateRange.today(),
      media: [],
      platforms: [],
      verifiedEnabled: false,
      minimumFollowerCountEnabled: false,
      minimumFollowerCount: 500,
      collapseSyndicated: false,
      bundleBroadcast: false,
      programs: [],
      frontPageRanks: { min: 0 },
      pageNumbers: { min: null, max: null },
      wordCountEnabled: false,
      dateRangeEnabled: false,
      sentimentEnabled: false,
      includedMentionsEnabled: false,
      lastSavedEnabled: false,
      frontPageRanksEnabled: false,
      pageNumbersEnabled: false,
      reactScoreAverageSelected: true,
      reactScoreBreakdown: false,
      includeBookmarkSyndication: false,
      reactScore: {
        min: 0,
        max: 100,
      },
      aggregateEmotionality: {
        min: 0,
        max: 100,
      },
      aggregateFakeness: {
        min: 0,
        max: 100,
      },
      aggregateSpam: {
        min: 0,
        max: 100,
      },
      aggregateHarmful: {
        min: 0,
        max: 100,
      },
    };
  }

  constructor(
    stream,
    {
      aggregateEmotionality,
      aggregateFakeness,
      aggregateHarmful,
      aggregateSpam,
      authors,
      booleanQuery,
      bundleBroadcast,
      collapseSyndicated,
      dateRangeEnabled,
      domainAuthority,
      excludedKeywords,
      excludedSources,
      excludedTags,
      externalItemsEnabled,
      frontPageRanks,
      frontPageRanksEnabled,
      includeBookmarkSyndication,
      includedMentionsEnabled,
      keywords,
      lastSavedEnabled,
      locations,
      media,
      minimumFollowerCount,
      minimumFollowerCountEnabled,
      onlyShowExternalItems,
      pageNumbers,
      pageNumbersEnabled,
      platforms,
      programs,
      range,
      reactScore,
      reactScoreAverageSelected,
      reactScoreBreakdown,
      sentiment,
      sentimentEnabled,
      sources,
      tags,
      tiers,
      useExactAuthors,
      verifiedEnabled,
      wordCount,
      wordCountEnabled,
    } = {}
  ) {
    this.stream = stream;
    this.initialFilters = {
      ...StreamFilters.DEFAULT_FILTERS,
      ...(keywords ? { keywords } : {}),
      ...(excludedKeywords ? { excludedKeywords } : {}),
      ...(booleanQuery ? { booleanQuery } : {}),
      ...(sources ? { sources } : {}),
      ...(tags ? { tags } : {}),
      ...(excludedTags ? { excludedTags } : {}),
      ...(excludedSources ? { excludedSources } : {}),
      ...(authors ? { authors } : {}),
      ...(locations ? { locations } : {}),
      ...(sentiment ? { sentiment } : {}),
      ...(tiers ? { tiers } : {}),
      ...(wordCount ? { wordCount } : {}),
      ...(onlyShowExternalItems ? { onlyShowExternalItems } : {}),
      ...(range ? { range } : {}),
      ...(media ? { media } : {}),
      ...(platforms ? { platforms } : {}),
      ...(minimumFollowerCountEnabled ? { minimumFollowerCountEnabled } : {}),
      ...(minimumFollowerCount ? { minimumFollowerCount } : {}),
      ...(collapseSyndicated ? { collapseSyndicated } : {}),
      ...(verifiedEnabled ? { verifiedEnabled } : {}),
      ...(programs ? { programs } : {}),
      ...(frontPageRanks ? { frontPageRanks } : {}),
      ...(pageNumbers ? { pageNumbers } : {}),
      ...(wordCountEnabled ? { wordCountEnabled } : {}),
      ...(dateRangeEnabled ? { dateRangeEnabled } : {}),
      ...(sentimentEnabled ? { sentimentEnabled } : {}),
      ...(lastSavedEnabled ? { lastSavedEnabled } : {}),
      ...(includedMentionsEnabled ? { includedMentionsEnabled } : {}),
      ...(frontPageRanksEnabled ? { frontPageRanksEnabled } : {}),
      ...(pageNumbersEnabled ? { pageNumbersEnabled } : {}),
      ...(reactScoreAverageSelected !== undefined
        ? { reactScoreAverageSelected }
        : {}),
      ...(reactScoreBreakdown !== undefined ? { reactScoreBreakdown } : {}),
      ...(reactScore ? { reactScore } : {}),
      ...(useExactAuthors ? { useExactAuthors } : false),
      ...(aggregateEmotionality ? { aggregateEmotionality } : {}),
      ...(aggregateFakeness ? { aggregateFakeness } : {}),
      ...(aggregateSpam ? { aggregateSpam } : {}),
      ...(aggregateHarmful ? { aggregateHarmful } : {}),
      ...(includeBookmarkSyndication ? { includeBookmarkSyndication } : {}),
      ...(bundleBroadcast ? { bundleBroadcast } : {}),
      ...(domainAuthority ? { domainAuthority } : {}),
    };

    if (this.canFilterExternalItems()) {
      this.initialFilters.externalItemsEnabled =
        externalItemsEnabled !== undefined ? externalItemsEnabled : true;
    }

    Object.assign(this, this.initialFilters);
  }

  static fromRequestFilters(requestFilters = {}) {
    const {
      sources,
      excludedSources,
      keywords,
      booleanQuery,
      excluded_keywords: excludedKeywords,
      page_numbers: pageNumbers,
      min_word_count: minWordCount,
      max_word_count: maxWordCount,
    } = requestFilters;
    let pageNumbersEnabled = false;

    if (pageNumbers !== undefined) {
      pageNumbersEnabled = true;
    }

    let wordCountEnabled = false;
    let wordCount = null;

    if (minWordCount !== undefined && maxWordCount !== undefined) {
      wordCountEnabled = true;
      wordCount = {
        min: minWordCount,
        max: maxWordCount,
      };
    }

    return new StreamFilters(null, {
      keywords,
      booleanQuery,
      excludedKeywords,
      sources,
      excludedSources,
      pageNumbersEnabled,
      pageNumbers,
      wordCountEnabled,
      wordCount,
    });
  }

  clone() {
    return Object.assign(new StreamFilters(this.stream, cloneDeep(this)), {
      initialFilters: cloneDeep(this.initialFilters),
    });
  }

  supportsExternalItems(stream) {
    if (isNullish(stream)) return false;

    return [
      streamTypes.mentionStream,
      streamTypes.bookmarkStream,
      streamTypes.organisationBrief,
    ].includes(stream.type);
  }

  canFilterExternalItems() {
    if (this.stream === null) {
      return features.has("has_external_items");
    }

    return (
      this.supportsExternalItems(this.stream) &&
      features.has("has_external_items")
    );
  }

  filteringContent({ ignoredFilters } = { ignoredFilters: [] }) {
    const contentFilters = {
      keywords: this.keywords && this.keywords !== "",
      excludedKeywords: this.excludedKeywords && this.excludedKeywords !== "",
      pageNumber:
        this.pageNumbersEnabled !== this.initialFilters.pageNumbersEnabled,
      wordCount: this.wordCountEnabled !== this.initialFilters.wordCountEnabled,
      externalItems:
        this.externalItemsEnabled !== this.initialFilters.externalItemsEnabled,
      dateRangeEnabled:
        this.dateRangeEnabled !== this.initialFilters.dateRangeEnabled,
      dateRange: !this.range.equals(this.initialFilters.range),
      sentiment: this.sentimentEnabled !== this.initialFilters.sentimentEnabled,
      lastSaved: this.lastSavedEnabled,
    };

    return Object.entries(contentFilters).some(
      ([filter, active]) => !ignoredFilters.includes(filter) && active
    );
  }

  rangeEquals(selected, initial) {
    return selected.min === initial.min && selected.max === initial.max;
  }

  filteringReactScore() {
    if (this.reactScoreBreakdown) {
      return true;
    }

    const reactScoreFilters = [
      this.reactScoreAverageSelected &&
        !this.rangeEquals(this.reactScore, this.initialFilters.reactScore),
      !this.reactScoreAverageSelected &&
        !this.rangeEquals(
          this.aggregateEmotionality,
          this.initialFilters.aggregateEmotionality
        ),
      !this.reactScoreAverageSelected &&
        !this.rangeEquals(
          this.aggregateFakeness,
          this.initialFilters.aggregateFakeness
        ),
      !this.reactScoreAverageSelected &&
        !this.rangeEquals(
          this.aggregateSpam,
          this.initialFilters.aggregateSpam
        ),
      !this.reactScoreAverageSelected &&
        !this.rangeEquals(
          this.aggregateHarmful,
          this.initialFilters.aggregateHarmful
        ),
    ];

    return reactScoreFilters.some((filter) => filter);
  }

  filteringSources() {
    const sourceFilters = [
      this.sources.length,
      this.excludedSources.length,
      this.categories.length,
      this.tiers?.length,
    ];

    return sourceFilters.some((filter) => filter);
  }

  filteringAuthors() {
    return this.authors.length;
  }

  filteringPlatforms() {
    return this.platforms.length;
  }

  filteringHandleOrSocialImpact() {
    const filters = [
      this.authors.length,
      this.verifiedEnabled !== this.initialFilters.verifiedEnabled,
      this.minimumFollowerCountEnabled !==
        this.initialFilters.minimumFollowerCountEnabled,
    ];

    return filters.some((filter) => filter);
  }

  filteringTags() {
    const tagFilters = [this.tags.length, this.excludedTags.length];

    return tagFilters.some((filter) => filter);
  }

  filteringLocations() {
    return this.locations.length;
  }

  filteringMedia(defaultMedia) {
    return this.media.length
      ? defaultMedia.length - this.media.length > 0
      : false;
  }

  requestFilters() {
    const {
      aggregateEmotionality,
      aggregateFakeness,
      aggregateHarmful,
      aggregateSpam,
      authors,
      booleanQuery,
      bundleBroadcast,
      categories,
      collapseSyndicated,
      dateRangeEnabled,
      excludedKeywords,
      excludedSources,
      excludedTags,
      externalItemsEnabled,
      frontPageRanks,
      frontPageRanksEnabled,
      includeBookmarkSyndication,
      keywords,
      lastSavedEnabled,
      locations,
      media,
      minimumFollowerCount,
      minimumFollowerCountEnabled,
      onlyShowExternalItems,
      pageNumbers,
      pageNumbersEnabled,
      platforms,
      programs,
      range,
      reactScore,
      reactScoreAverageSelected,
      sentiment,
      sentimentEnabled,
      sources,
      tags,
      tiers,
      verifiedEnabled,
      wordCount,
      wordCountEnabled,
    } = this;

    const params = {};
    if (keywords !== "") params.keywords = keywords;
    if (excludedKeywords !== "") params.excluded_keywords = excludedKeywords;
    if (booleanQuery !== "") params.boolean_query = booleanQuery;
    if (sources.length > 0) params.source_keys = sourceKeysFor(sources);
    if (sources.length > 0) params.source_group_keys = sourceGroupKeys(sources);
    if (tags.length > 0) params.tag_ids = tags.map((tag) => tag.id);
    if (excludedTags.length > 0)
      params.excluded_tag_ids = excludedTags.map(
        (excludedTag) => excludedTag.id
      );

    if (excludedSources.length > 0) {
      params.excluded_source_keys = sourceKeysFor(excludedSources);
      params.excluded_source_group_keys = sourceGroupKeys(excludedSources);
    }

    if (authors.length)
      params.authors = authors
        .map((author) => author.name || author)
        .join(", ");

    if (wordCountEnabled) {
      params.min_word_count = wordCount.min;
      params.max_word_count = wordCount.max;
    }

    if (this.canFilterExternalItems()) {
      params.include_external_items = externalItemsEnabled;

      if (externalItemsEnabled) {
        params.only_external_items = onlyShowExternalItems;
      }
    }

    if (locations.length) {
      params.location_ids = locations;
    }

    if (dateRangeEnabled) {
      params.range = range;
    }

    if (sentimentEnabled) {
      params.sentiment = sentiment;
    }

    if (media.length > 0) params.media = media;

    if (platforms.length > 0) {
      params.types = platformsToMediaTypes(platforms);
    }

    if (verifiedEnabled) params.verified = verifiedEnabled;

    if (minimumFollowerCountEnabled)
      params.minimum_follower_count = minimumFollowerCount;

    if (categories.length) params.categories = categories;

    if (lastSavedEnabled) params.lastSavedEnabled = lastSavedEnabled;

    if (collapseSyndicated) params.collapse_syndicated = collapseSyndicated;

    if (bundleBroadcast) params.bundle_broadcast = bundleBroadcast;

    if (tiers.length) {
      const selectedTiers = tiers.map((tier) => tier.value);

      if (media.length > 0) {
        media.forEach((medium) => {
          params[`${medium.toLowerCase()}_tiers`] = selectedTiers;
        });
      } else if (this.stream !== null) {
        this.stream.enabled_media.forEach((medium) => {
          params[`${medium.toLowerCase()}_tiers`] = selectedTiers;
        });
      }
    }

    if (
      this.stream?.type === streamTypes.bookmarkStream &&
      typeof includeBookmarkSyndication === "boolean"
    ) {
      params.include_bookmark_syndication = includeBookmarkSyndication;
    }

    if (programs.length) params.programs = programs;

    if (frontPageRanksEnabled) params.front_page_ranks = frontPageRanks;

    if (
      pageNumbersEnabled &&
      pageNumbers.min !== null &&
      pageNumbers.max !== null
    ) {
      params.page_numbers = pageNumbers;
    }

    if (
      this.stream &&
      this.stream.type !== streamTypes.socialStream &&
      this.filteringReactScore()
    ) {
      if (reactScoreAverageSelected) {
        params.min_react_score = reactScore.min / 100;
        params.max_react_score = reactScore.max / 100;
      } else {
        params.min_aggregate_emotionality = aggregateEmotionality.min / 100;
        params.max_aggregate_emotionality = aggregateEmotionality.max / 100;

        params.min_aggregate_fakeness = aggregateFakeness.min / 100;
        params.max_aggregate_fakeness = aggregateFakeness.max / 100;

        params.min_aggregate_spam = aggregateSpam.min / 100;
        params.max_aggregate_spam = aggregateSpam.max / 100;

        params.min_aggregate_harmful = aggregateHarmful.min / 100;
        params.max_aggregate_harmful = aggregateHarmful.max / 100;
      }
    }

    if (this.useExactAuthors) params.use_exact_authors = this.useExactAuthors;

    if (this.domainAuthority && Object.keys(this.domainAuthority).length) {
      params.min_domain_authority = this.domainAuthority.min;
      params.max_domain_authority = this.domainAuthority.max;
    }

    return params;
  }
}
