import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Contact from "./Contact";
import ContactMailingList from "./ContactMailingList";

@Model()
class ContactMailingListsContact extends ApplicationRecord {
  static jsonapiType = "contact_mailing_lists_contacts";

  @BelongsTo() contact: Contact;

  @BelongsTo() contactMailingList: ContactMailingList;

  @Attr() contactId: string;

  @Attr() contactType: string;

  @Attr() contactMailingListId: number;

  @Attr() avatarUrl: string;
}

export default ContactMailingListsContact;
