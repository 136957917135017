import { storeToRefs } from "pinia";

import { getLocaleText } from "shared/boot/i18n";
import { useUserLocation } from "shared/composables/useUserLocation";
import {
  DAY_IN_MILLISECONDS,
  EUROPE,
  EUROPE_EXCLUDED_LOCATION_IDS,
  NORTH_AMERICA,
  STREAM_EXTENDED_EXPIRY_DAYS,
  streamTypes,
} from "shared/constants";
import {
  getTopLocations,
  shouldIncludeLocation,
} from "shared/helpers/location";
import {
  defaultContentToggles,
  defaultSocialContentToggles,
  getSources,
} from "shared/helpers/media";
import { useGlobalStore } from "shared/stores/global";

// used to test if a stream prop passed to a component is different so we can reload
export function isSameStream(newStream, oldStream) {
  return oldStream && newStream.id === oldStream.id;
}

export function labelForStreamType(type = streamTypes.mentionStream) {
  if (!Number.isInteger(Object.values(streamTypes)[type]))
    return getLocaleText(`stream_type_labels.0`);

  return getLocaleText(`stream_type_labels.${type}`);
}

export function getContentFlags(stream) {
  return {
    online_content: stream.online_content,
    print_content: stream.print_content,
    magazine_content: stream.magazine_content,
    podcast_content: stream.podcast_content,
    tv_content: stream.tv_content,
    radio_content: stream.radio_content,
    social_content: stream.social_content,
  };
}

export function expiryHasExtended(
  newStream,
  oldStream,
  days = STREAM_EXTENDED_EXPIRY_DAYS
) {
  return (
    new Date(newStream.expires_at || 0) -
      new Date(oldStream.expires_at || 0) ===
    days * DAY_IN_MILLISECONDS
  );
}

export function mentionStreamDefaultMediaTypes() {
  return defaultContentToggles();
}

export function mentionStreamSelectedMediaTypes() {
  return getSources()
    .map(({ field }) => ({ [`selected_${field}_content`]: null }))
    .reduce((acc, source) => ({ ...acc, ...source }), {});
}

export function socialStreamDefaultMediaTypes() {
  return defaultSocialContentToggles();
}

export function defaultLocationFilters(
  locations,
  sources,
  organisation = null
) {
  const filters = [];

  const { getUserLocation } = useUserLocation(organisation);
  const userLocation = getUserLocation();
  let excludedLocations = [];

  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const isAU = appConfig.value.platformName === "Streem";
  const topLocations = getTopLocations(locations, userLocation);

  locations.forEach((location) => {
    if (
      location.type === "Global" ||
      // Only include North America in AU environment
      (!isAU && location.name === NORTH_AMERICA.name)
    ) {
      return;
    }

    let includeLocation = shouldIncludeLocation(location, userLocation);

    const isTopLocation = topLocations.some(
      (topLocation) => topLocation.id === location.id
    );

    if (!includeLocation && !isTopLocation) {
      const locationParent = locations.find(
        (parentLocation) => parentLocation.id === location.parent_id
      );

      if (
        locationParent &&
        locationParent.type !== "Global" &&
        shouldIncludeLocation(locationParent, userLocation)
      ) {
        includeLocation = true;
      }
    }

    if (!includeLocation) {
      const includedChildLocations = locations
        .filter((childLocation) => {
          const isChild = childLocation.parent_id === location.id;

          if (location.name === EUROPE.name) {
            return (
              isChild &&
              !EUROPE_EXCLUDED_LOCATION_IDS.includes(childLocation.country_code)
            );
          }

          return isChild;
        })
        .some((childLocation) =>
          shouldIncludeLocation(childLocation, userLocation)
        );

      if (includedChildLocations) {
        includeLocation = true;
      }
    }

    if (!includeLocation) {
      excludedLocations.push(location);
    }
  });

  excludedLocations = excludedLocations.filter((location) => {
    const isTopLocation = topLocations.some(
      (topLocation) => topLocation.id === location.id
    );

    if (isTopLocation) return true;

    const locationParent = locations.find(
      (parentLocation) => parentLocation.id === location.parent_id
    );

    if (!locationParent) return true;

    const isParentExcluded = excludedLocations.some(
      (excludedLocation) => excludedLocation.id === locationParent.id
    );

    return !isParentExcluded;
  });

  excludedLocations.forEach((location) => {
    sources.forEach(({ field }) => {
      if (field === "social") return;
      filters.push({
        target_type: "Location",
        scope: field,
        target_id: location.id,
        action: "exclude",
        name: location.name,
        filter: { id: location.id, visible: location.visible },
      });
    });
  });

  const topLocationsFiltered = topLocations.every((location) =>
    filters.some((filter) => filter.target_id === location.id)
  );

  // If all top locations available have been filtered out, include the next top location
  if (topLocationsFiltered) {
    return filters.filter(
      (filter) =>
        filter.target_type === "Location" &&
        filter.target_id !== topLocations[0].id
    );
  }

  return filters;
}

export function defaultCategoryFilters(categories, sources) {
  const filters = [];

  categories.forEach((category) => {
    if (category.name === "Aggregator") {
      sources.forEach(({ field }) => {
        if (field === "social") return;
        filters.push({
          target_type: "Category",
          scope: field,
          target_id: category.id,
          action: "exclude",
          filter: { id: category.id },
        });
      });
    }
  });

  return filters;
}

export function mentionStreamDefaultMedia() {
  return getSources()
    .map(({ field }) => ({ [`${field}_content`]: true }))
    .reduce((acc, source) => ({ ...acc, ...source }), {});
}

export function socialStreamDefaultMedia() {
  return getSources()
    .map(({ field }) => ({ [`${field}_content`]: field === "social" }))
    .reduce((acc, source) => ({ ...acc, ...source }), {});
}

export function labelForCoverage(type = streamTypes.mentionStream) {
  if (type === streamTypes.socialStream) return "social posts";

  return "coverage";
}
