<template>
  <div
    class="alert"
    :class="`alert-${type}`"
  >
    <div v-if="!hideIcon">
      <QIcon
        :name="alertIcon"
        :size="iconSize"
      />
    </div>
    <div class="q-ml-sm">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export const ALERT_ICONS = {
  warning: "ion-alert",
  info: "info",
  success: "ion-checkmark-circle-outline",
} as const;
</script>

<script setup lang="ts">
import { computed } from "vue";

export type AlertIconMap = typeof ALERT_ICONS;
export type AlertType = keyof AlertIconMap;
export type AlertIcon = AlertIconMap[AlertType];

export interface Props {
  type?: AlertType;
  iconSize?: string;
  hideIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  type: "warning",
  iconSize: "1.5rem",
});

const alertIcon = computed<AlertIcon>(() => ALERT_ICONS[props.type]);
</script>

<style lang="scss" scoped>
.alert {
  background-color: $ds-orange-1;
  border: 1px solid $ds-orange-5;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: $ds-orange-5;

  &-warning {
    background-color: $ds-orange-1;
    border-color: $ds-orange-5;
    color: $ds-orange-5;
  }

  &-info {
    background-color: $ds-blue-1;
    border-color: $ds-blue-4;
    color: $ds-blue-6;
  }

  &-success {
    background-color: $ds-green-1;
    border-color: $ds-green-4;
    color: $ds-green-6;
  }
}
</style>
