import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { getSource } from "shared/helpers/media";
import { useUserStore } from "shared/stores/user";

const handleErrors = true;

export default {
  getActivitiesCount(mention) {
    const source = getSource(mention.type);

    return $streemApiV1
      .get(`organisations/${useUserStore().organisation.id}/activities`, {
        params: {
          target_type: source.relatedCamelCaseField,
          target_id: mention.id,
          limit: 0,
        },
        handleErrors,
      })
      .then((response) => parseInt(response.headers["x-total-count"], 10));
  },

  createActivity(mention) {
    const source = getSource(mention.type);
    track("Created Activity", { feature: source.viewFeatureFlag });

    return $streemApiV1.post("activities", {
      params: {
        feature: source.viewFeatureFlag,
        target_type: source.relatedCamelCaseField,
        target_id: mention.id,
      },
      handleErrors,
    });
  },
};
