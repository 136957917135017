<template>
  <MentionCard
    :title="mention.title"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :list-title="mention.title"
    :options="options"
    :no-highlighting="noHighlighting"
    :show-checkbox="showCheckbox"
    :icon="externalItemIcon"
    :selected="selected"
    class="pointer"
    @mention-selected="$emit('mention-selected', $event)"
    @click="mentionClicked(mention)"
  >
    <template #header>
      <span class="bold">
        {{ mention.source.name }}
      </span>
      <TimeAgo
        class="text-ds-denim-7"
        :date="mention.timestamp"
        :timezone="mention.time_zone"
      />
    </template>

    <template #body>
      <span>
        {{ mention.excerpts[0].text }}
      </span>
      <p class="text-ds-denim-7 q-mt-sm">
        {{
          $t("external_item_mention_card.added_by", {
            createdBy: mention.created_by,
            createdAt: createdAt(mention),
            displayedTimezone: displayedTimezone(mention),
          })
        }}
      </p>
    </template>

    <slot />

    <template #list-body>
      <p>
        {{ mention.excerpts[0].text }}
      </p>
      <div class="q-my-md">
        <p class="text-ds-denim-7">
          {{
            $t("external_item_mention_card.added_by", {
              createdBy: mention.created_by,
              createdAt: createdAt(mention),
              displayedTimezone: displayedTimezone(mention),
            })
          }}
        </p>
        <span
          class="hover-active bold q-my-md"
          @click.stop="mentionClicked(mention)"
        >
          {{ $t("external_item_mention_card.view_external_item") }}
        </span>
      </div>
    </template>

    <template #afterBody>
      <div class="q-mt-md">
        <span
          class="hover-active bold q-my-md"
          @click.stop="mentionClicked(mention)"
        >
          {{ $t("external_item_mention_card.view_external_item") }}
        </span>
      </div>

      <div
        v-if="displayAudience(mention)"
        class="row soft q-mt-sm items-baseline"
      >
        <span
          v-if="mention.audience"
          class="flex items-baseline"
        >
          <QIcon
            name="ion-ios-people"
            class="q-mr-xs"
          />
          {{ suffix(mention.audience) }}
        </span>
      </div>
    </template>

    <slot />
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";

import { MentionCard, props } from "shared/components/MentionCard";
import useMention from "shared/composables/useMention";
import { formatIntlDate, parseDate, shortTimezone } from "shared/helpers/date";
import { externalItemIcon } from "shared/helpers/media";
import { suffix } from "shared/helpers/number";
import { useUniversalSearchStore } from "shared/stores/universalSearch";
import { useUserStore } from "shared/stores/user";

export default {
  name: "ExternalItemMentionCard",
  components: {
    MentionCard,
  },
  props,
  emits: ["mention-selected"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    const universalSearchStore = useUniversalSearchStore();
    const { searchOpen } = storeToRefs(universalSearchStore);

    const userStore = useUserStore();
    const { isAdminUser } = storeToRefs(userStore);

    return { mentionClicked, isAdminUser, searchOpen };
  },
  data() {
    return {
      externalItemIcon,
    };
  },
  methods: {
    suffix,
    displayedTimezone(mention) {
      return shortTimezone(parseDate(mention.timestamp), mention.time_zone);
    },
    displayAudience(mention) {
      return mention.medium !== "Social";
    },
    createdAt(mention) {
      return formatIntlDate(new Date(mention.created_at), {
        dateStyle: "short",
        timeStyle: "short",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.mention-stream-view-card) {
  border-top: 1px solid $ds-denim-3;
  border-right: 1px solid $ds-denim-3;
  border-bottom: 1px solid $ds-denim-3;
}

:deep(.card) {
  background-color: $ds-denim-2;
}
</style>
