<template>
  <ChartLibraryChart v-bind="chartProps" />
</template>

<script>
import { merge } from "lodash-es";

import ChartLibraryChart from "shared/components/ChartLibraryChart";
import { formatDate } from "shared/helpers/date";
import { filterSocialPlatformsByTypes, getSources } from "shared/helpers/media";

const innerAggregationKey = "3";

export default {
  name: "MentionVolumeChart",
  components: {
    ChartLibraryChart,
  },
  props: {
    histogram: {
      type: Array,
      required: true,
    },
    height: {
      type: [Number, String],
      default: 180,
    },
    enableYAxis: {
      type: Boolean,
    },
    collapseSyndicated: {
      type: Boolean,
    },
    columnType: {
      type: String,
      default: "medium",
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    maxTicksLimit: {
      type: Number,
      default: 11,
    },
    contentTypes: {
      type: Array,
      default: () => [],
    },
    flatXticks: {
      type: Boolean,
    },
    enableYTickSuffix: {
      type: Boolean,
    },
  },
  data() {
    return {
      chartData: null,
    };
  },
  computed: {
    interval() {
      const model = this.histogram[0][2].buckets;

      if (model.length < 2) {
        return "1m";
      }

      return model[1].key - model[0].key <= 3600 * 1000 ? "1h" : "1m";
    },
    options() {
      const defaultOptions = {
        plugins: {
          clickevents: false,
        },
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              font: {
                size: 8,
              },
              autoSkip: this.maxTicksLimit > 0,
              maxTicksLimit: this.maxTicksLimit,
              ...(this.flatXticks ? { maxRotation: 0, minRotation: 0 } : {}),
            },
          },
          y: {
            display: this.enableYAxis,
            ticks: {
              autoSkip: this.maxTicksLimit > 0,
              maxTicksLimit: this.maxTicksLimit,
            },
          },
        },
      };

      return merge(defaultOptions, this.chartOptions);
    },
    chartProps() {
      return {
        chartData: {
          data: this.chartData,
          options: this.options,
        },
        chartConfig: {
          component: "Chart",
          componentAttributes: {
            type: "barStacked",
          },
          style: { height: `${Number(this.height)}px` },
        },
      };
    },
  },
  watch: {
    histogram: {
      handler() {
        const data = this.histogram[0][2].buckets;

        const columns = this.setupColumns();

        Object.keys(data).forEach((key) => {
          this.histogram.forEach((value) => {
            const relevantColumn = columns.find(
              (column) => column.field === value.key.toLowerCase()
            );
            const bucket = value[2].buckets[key];

            if (relevantColumn && bucket) {
              relevantColumn.mentionsByInterval[key] =
                this.getBucketCount(bucket);
            }
          });
        });

        // Generate labels (ensure we display 24h data if the inverval is 1h)
        const labels = data.map(({ key }) => key);

        if (this.interval === "1h") {
          for (let index = 0; index < 24 - data.length; index += 1) {
            labels.push(labels.slice(-1).pop() + 3600 * 1000);
          }
        }

        this.chartData = {
          labels: labels.map((label) =>
            formatDate(label, this.interval === "1m" ? "d MMM" : "ha")
          ),
          datasets: columns.map((column) => ({
            label: column.label,
            data: column.mentionsByInterval,
            backgroundColor: column.color,
          })),
        };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    mapColumns(columns, fieldKey) {
      return columns.map((column) => ({
        label: column.label,
        color: column.color,
        field: column[fieldKey],
        mentionsByInterval: [],
      }));
    },
    setupColumns() {
      const columns =
        this.columnType === "medium"
          ? getSources()
          : filterSocialPlatformsByTypes(this.contentTypes);
      const fieldKey = this.columnType === "medium" ? "field" : this.columnType;

      return this.mapColumns(columns, fieldKey);
    },
    getBucketCount(bucket) {
      return this.collapseSyndicated
        ? bucket.unique?.value || bucket[innerAggregationKey]?.value
        : bucket.doc_count;
    },
  },
};
</script>
