<template>
  <div
    class="card q-mb-md e2e-expandable-filters"
    :class="$isMobile && stickFilters && 'fixed-top q-header show-title-header'"
  >
    <QList class="header text-ds-denim-7">
      <QExpansionItem
        :label="$t('expandable_filters.content')"
        v-bind="expandableGroupLabel"
        dense
        default-opened
        :caption="displayContent"
        :class="{ active: displayActive('content'), 'q-px-md': !noPaddingX }"
        class="q-py-sm e2e-content-expandable"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('content')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <ContentExpandable
              v-model:keywords="localFilters.keywords"
              v-model:excluded-keywords="localFilters.excludedKeywords"
              v-model:page-numbers="localFilters.pageNumbers"
              v-model:page-numbers-enabled="localFilters.pageNumbersEnabled"
              v-model:word-count-enabled="localFilters.wordCountEnabled"
              v-model:word-count="localFilters.wordCount"
              v-model:date-range-enabled="localFilters.dateRangeEnabled"
              v-model:selected-date-range="localFilters.range"
              v-model:external-items-enabled="localFilters.externalItemsEnabled"
              v-model:only-show-external-items="
                localFilters.onlyShowExternalItems
              "
              v-model:sentiment-enabled="localFilters.sentimentEnabled"
              v-model:sentiment="localFilters.sentiment"
              v-model:last-saved-enabled="localFilters.lastSavedEnabled"
              v-model:included-mentions-enabled="
                localFilters.includedMentionsEnabled
              "
              :show-included-mentions="showIncludedMentions"
              :stream="stream"
              :filters="localFilters"
              :hide-date-range="hideDateRange"
              :show-last-saved-toggle="showLastSavedToggle"
              :hide-word-count="isSocialStream"
              :hide-page-numbers="isSocialStream"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator
        v-if="showReactScoreFilters"
        color="ds-denim-2"
      />

      <QExpansionItem
        v-if="showReactScoreFilters"
        :label="$t('expandable_filters.react_score')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displayReactScore"
        :class="{ active: displayActive('reactScore'), 'q-px-md': !noPaddingX }"
        class="q-py-sm"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('reactScore')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <ReactScoreExpandable
              v-model:react-score="localFilters.reactScore"
              v-model:react-score-average-selected="
                localFilters.reactScoreAverageSelected
              "
              v-model:aggregate-emotionality="
                localFilters.aggregateEmotionality
              "
              v-model:aggregate-fakeness="localFilters.aggregateFakeness"
              v-model:aggregate-spam="localFilters.aggregateSpam"
              v-model:aggregate-harmful="localFilters.aggregateHarmful"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator
        v-if="!isSocialStream"
        color="ds-denim-2"
      />

      <QExpansionItem
        v-if="!isSocialStream"
        :label="$t('expandable_filters.source')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displaySources"
        :class="{ active: displayActive('sources'), 'q-px-md': !noPaddingX }"
        class="q-py-sm e2e-sources-expandable"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('sources')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <SourcesExpandable
              v-model:sources="localFilters.sources"
              v-model:excluded-sources="localFilters.excludedSources"
              v-model:authors="localFilters.authors"
              v-model:categories="localFilters.categories"
              v-model:tiers="localFilters.tiers"
              :stream="stream"
              :show-categories="showCategories"
              :show-tiers="showTiers"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator color="ds-denim-2" />

      <!-- Disabling for now... -->
      <template v-if="false">
        <QExpansionItem
          :label="$t('expandable_filters.location')"
          v-bind="expandableGroupLabel"
          dense
          :caption="displayLocations"
          :class="{
            active: displayActive('locations'),
            'q-px-md': !noPaddingX,
          }"
          class="q-py-sm"
          expand-icon-class="text-ds-denim-9"
          @click="toggleActive('locations')"
        >
          <QCard>
            <QCardSection class="q-py-none q-px-xs">
              <LocationSelector
                :selected-locations="localFilters.locations"
                :style="{ paddingLeft: $isDesktop ? '30px' : '20px' }"
                small
                @toggle-filter="toggleLocation"
              />
            </QCardSection>
          </QCard>
        </QExpansionItem>

        <QSeparator color="ds-denim-2" />
      </template>

      <QExpansionItem
        v-if="isSocialStream"
        :label="$t('expandable_filters.social_platform')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displayPlatforms"
        :class="{ active: displayActive('platforms'), 'q-px-md': !noPaddingX }"
        class="q-py-sm e2e-platforms-expandable"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('platforms')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <SocialPlatformsExpandable
              v-model:platforms="handlePlatforms"
              v-model:refresh-platforms="refreshPlatforms"
              :stream="stream"
              :verified-enabled="localFilters.verifiedEnabled"
              :minimum-follower-count-enabled="
                localFilters.minimumFollowerCountEnabled
              "
              :minimum-follower-count="localFilters.minimumFollowerCount"
              inline
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QExpansionItem
        v-else
        :label="$t('expandable_filters.media_type')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displayMedia"
        :class="{ active: displayActive('media'), 'q-px-md': !noPaddingX }"
        class="q-py-sm e2e-media-expandable"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('media')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <MediaSelector
              v-model:media="localFilters.media"
              v-model:refresh-media="refreshMedia"
              :stream="stream"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator
        v-if="isSocialStream"
        color="ds-denim-2"
      />

      <QExpansionItem
        v-if="isSocialStream"
        v-bind="expandableGroupLabel"
        dense
        :class="{
          active: displayActive('socialImpact'),
          'q-px-md': !noPaddingX,
        }"
        class="q-py-sm"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('socialImpact')"
      >
        <template #header>
          <QItemSection>
            <QItemLabel> {{ $t("expandable_filters.author") }} </QItemLabel>

            <QItemLabel class="text-caption text-error">
              {{ displayHandleOrSocialImpact }}
            </QItemLabel>
          </QItemSection>
        </template>

        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <AuthorsExpandable
              v-model:authors="localFilters.authors"
              content-style="bold"
              @search="applySearch"
            />

            <SocialImpactExpandable
              v-model:minimum-follower-count-enabled="
                localFilters.minimumFollowerCountEnabled
              "
              v-model:minimum-follower-count="localFilters.minimumFollowerCount"
              v-model:verified-enabled="localFilters.verifiedEnabled"
              span-class="bold block q-mt-sm"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator color="ds-denim-2" />

      <QExpansionItem
        :label="$t('expandable_filters.tag')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displayTags"
        :class="{ active: displayActive('tags'), 'q-px-md': !noPaddingX }"
        class="q-py-sm"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('tags')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <DropdownTags
              v-model:tags="localFilters.tags"
              :stream="stream"
              :hide-input="Boolean(localFilters.excludedTags.length)"
              :hide-input-label="$t('dropdown_tags.tags_error')"
              class="q-mb-md"
              @search="applySearch"
            />

            <span class="block bold q-mb-sm">
              {{ $t("dropdown_tags.excluded_tags") }}
            </span>

            <DropdownTags
              v-model:tags="localFilters.excludedTags"
              :stream="stream"
              :hide-input="Boolean(localFilters.tags.length)"
              :hide-input-label="$t('dropdown_tags.exclude_tags_error')"
              class="q-mb-md"
              @search="applySearch"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator color="ds-denim-2" />

      <QExpansionItem
        v-if="includeSorting"
        :label="$t('expandable_filters.sorting')"
        v-bind="expandableGroupLabel"
        dense
        :caption="displaySorting"
        :class="{ active: displayActive('sorting'), 'q-px-md': !noPaddingX }"
        class="q-py-sm"
        expand-icon-class="text-ds-denim-9"
        @click="toggleActive('sorting')"
      >
        <QCard>
          <QCardSection class="q-py-none q-px-xs">
            <SortingExpandable
              v-model="handleSortingOptions"
              :excluded-sorting-options="excludedSortingOptions"
            />
          </QCardSection>
        </QCard>
      </QExpansionItem>

      <QSeparator
        v-if="includeSorting"
        color="ds-denim-2"
      />
    </QList>

    <div class="row full-width justify-end">
      <slot name="reset-button">
        <BaseButton
          new-design
          class="q-ma-xs"
          :label="$t('expandable_filters.reset')"
          @click="reset"
        />
      </slot>
      <slot
        v-if="showApply"
        name="apply-button"
      >
        <BaseButton
          new-design
          color="primary"
          class="q-ma-xs e2e-apply-filters"
          :label="$t('expandable_filters.apply')"
          @click="apply"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import { storeToRefs } from "pinia";

import { BaseButton } from "shared/components/base";
import AuthorsExpandable from "shared/components/selectors/expandables/AuthorsExpandable.vue";
import ContentExpandable from "shared/components/selectors/expandables/ContentExpandable.vue";
import DropdownTags from "shared/components/selectors/expandables/DropdownTags.vue";
import ReactScoreExpandable from "shared/components/selectors/expandables/ReactScoreExpandable.vue";
import SocialImpactExpandable from "shared/components/selectors/expandables/SocialImpactExpandable.vue";
import SocialPlatformsExpandable from "shared/components/selectors/expandables/SocialPlatformsExpandable.vue";
import SortingExpandable from "shared/components/selectors/expandables/SortingExpandable.vue";
import SourcesExpandable from "shared/components/selectors/expandables/SourcesExpandable.vue";
import LocationSelector from "shared/components/selectors/LocationSelector.vue";
import MediaSelector from "shared/components/selectors/MediaSelector.vue";
import { streamTypes } from "shared/constants";
import { toSentence } from "shared/helpers/array";
import DateRange from "shared/helpers/DateRange";
import {
  getEnabledSocialPlatformsForStream,
  getSources,
  social,
  sourceByMedium,
} from "shared/helpers/media";
import {
  excludedMediaItemSortFields,
  excludedTranscriptRequestSortFields,
  getSortOptions,
} from "shared/helpers/mentions";
import StreamFilters from "shared/helpers/StreamFilters";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "ExpandableFilters",
  components: {
    DropdownTags,
    ReactScoreExpandable,
    BaseButton,
    AuthorsExpandable,
    ContentExpandable,
    LocationSelector,
    SortingExpandable,
    SocialPlatformsExpandable,
    SocialImpactExpandable,
    MediaSelector,
    SourcesExpandable,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    dateRange: {
      type: DateRange,
      default: () => DateRange.today(),
    },
    filters: {
      type: StreamFilters,
      default: null,
    },
    sortingOptions: {
      type: Array,
      default: () => [],
    },
    noPaddingX: {
      type: Boolean,
    },
    showMultiple: {
      type: Boolean,
    },
    includeSorting: {
      type: Boolean,
    },
    stickFilters: {
      type: Boolean,
    },
    hideDateRange: {
      type: Boolean,
    },
    showCategories: {
      type: Boolean,
    },
    showIncludedMentions: {
      type: Boolean,
    },
    showLastSavedToggle: {
      type: Boolean,
    },
    showTiers: {
      type: Boolean,
    },
    showApply: {
      type: Boolean,
    },
  },
  emits: [
    "update:sortingOptions",
    "update:filteringContent",
    "update:filteringReactScore",
    "update:filteringSources",
    "update:filteringTags",
    "update:filteringExcludedTags",
    "update:filteringHandleOrSocialImpact",
    "update:filteringLocations",
    "update:filteringMedia",
    "update:filteringPlatforms",
    "update:filteringSocialImpact",
    "update:filters",
    "search",
    "reset",
    "apply",
  ],
  setup() {
    const globalStore = useGlobalStore();
    const { locations } = storeToRefs(globalStore);

    return { locations };
  },
  data() {
    return {
      localFilters: new StreamFilters(this.stream),
      refreshMedia: true,
      refreshPlatforms: true,
      activeTab: "content",
      debouncedSearch: debounce(this.search, 600),
    };
  },
  computed: {
    handlePlatforms: {
      get() {
        return this.localFilters.platforms;
      },
      set(value) {
        this.localFilters.platforms =
          this.enabledPlatforms.length === value.length ? [] : value;
      },
    },
    handleSortingOptions: {
      get() {
        return this.sortingOptions;
      },
      set(val) {
        this.$emit("update:sortingOptions", val);
      },
    },
    enabledPlatforms() {
      return getEnabledSocialPlatformsForStream(this.stream);
    },
    excludedSortingOptions() {
      if (this.stream.isMediaItems) {
        return excludedMediaItemSortFields;
      }

      if (this.stream.isTranscriptRequest) {
        return excludedTranscriptRequestSortFields;
      }

      return [];
    },
    isSocialStream() {
      return (
        this.stream.type === streamTypes.socialStream ||
        this.stream.social_bookmarks_stream
      );
    },
    expandableGroupLabel() {
      return this.showMultiple
        ? {}
        : { group: `${this.stream.id}-expandables` };
    },
    sortingBy() {
      if (this.sortingOptions.length === 1) return [];

      const possibilities = getSortOptions().reduce(
        (obj, possibility) => ({
          ...obj,
          [possibility.field]: possibility.label,
        }),
        {}
      );

      return this.sortingOptions.map((option) => possibilities[option.sortBy]);
    },
    filteringByContent() {
      const filters = [];

      if (this.localFilters.keywords && this.localFilters.keywords !== "") {
        filters.push(this.$t("expandable_filters.keywords"));
      }

      if (
        this.localFilters.excludedKeywords &&
        this.localFilters.excludedKeywords !== ""
      ) {
        filters.push(this.$t("expandable_filters.excluded_keywords"));
      }

      if (this.localFilters.wordCountEnabled) {
        filters.push(this.$t("expandable_filters.word_count"));
      }

      if (this.localFilters.dateRangeEnabled) {
        filters.push(this.$t("expandable_filters.date"));
      }

      if (this.localFilters.sentimentEnabled) {
        filters.push(this.$t("expandable_filters.sentiment"));
      }

      if (this.localFilters.lastSavedEnabled) {
        filters.push(this.$t("expandable_filters.last_saved"));
      }

      if (this.localFilters.externalItemsEnabled) {
        filters.push(this.$t("expandable_filters.external_items"));
      }

      if (this.localFilters.includedMentionsEnabled) {
        filters.push(this.$t("expandable_filters.included_mentions"));
      }

      return filters;
    },
    filteringBySources() {
      const filters = [];

      if (this.localFilters.sources.length) {
        filters.push(this.$t("expandable_filters.sources"));
      }

      if (this.localFilters.excludedSources.length) {
        filters.push(this.$t("expandable_filters.excluded_sources"));
      }

      if (this.localFilters.authors.length) {
        filters.push(this.$t("expandable_filters.authors"));
      }

      if (this.localFilters.categories.length) {
        filters.push(this.$t("expandable_filters.categories"));
      }

      if (this.localFilters.tiers && this.localFilters.tiers.length) {
        filters.push(this.$t("expandable_filters.tiers"));
      }

      return filters;
    },
    filteringByTags() {
      const filters = [];

      if (this.localFilters.tags.length) {
        filters.push(this.$t("expandable_filters.tag").toLowerCase());
      }

      if (this.localFilters.excludedTags.length) {
        filters.push(this.$t("expandable_filters.excluded_tags").toLowerCase());
      }

      return filters;
    },
    defaultEnabledMedia() {
      return getSources()
        .filter(({ medium }) => this.stream.enabled_media.includes(medium))
        .map((medium) => medium.medium);
    },
    defaultEnabledSocialMediaTypes() {
      return [...social.relatedTypes]
        .filter((type) =>
          this.stream.selected_social_content.includes(type.field)
        )
        .map((type) => type.field);
    },
    filteringByMedia() {
      if (!this.localFilters.media.length) return [];

      return this.defaultEnabledMedia
        .filter((medium) => !this.localFilters.media.includes(medium))
        .map((medium) => sourceByMedium[medium].label);
    },
    filteringByPlatforms() {
      if (!this.localFilters.platforms.length) return [];

      const platformLabels = [];
      [...social.platforms].forEach((platform) => {
        const relevantPlatform = this.defaultEnabledSocialMediaTypes.some(
          (mediaType) => platform.fields.includes(mediaType)
        );

        if (
          relevantPlatform &&
          !this.localFilters.platforms.includes(platform.platform)
        ) {
          platformLabels.push(platform.label);
        }
      });

      return platformLabels;
    },
    filteringByHandleOrSocialImpact() {
      const filters = [];
      const {
        authors,
        verifiedEnabled,
        minimumFollowerCountEnabled,
        minimumFollowerCount,
      } = this.localFilters;

      if (verifiedEnabled) {
        filters.push(this.$t("expandable_filters.verified").toLowerCase());
      }

      if (minimumFollowerCountEnabled && minimumFollowerCount) {
        filters.push(this.$t("expandable_filters.minimum_followers"));
      }

      if (authors.length) filters.push(this.$t("expandable_filters.handle"));

      return filters;
    },
    filteringByLocations() {
      if (this.localFilters.locations.length) {
        return [this.$t("expandable_filters.location").toLowerCase()];
      }

      return [];
    },
    displayContent() {
      this.$emit("update:filteringContent", this.filteringByContent.length > 0);

      return this.displayFiltering("content");
    },
    displaySources() {
      this.$emit("update:filteringSources", this.filteringBySources.length > 0);

      return this.displayFiltering("sources");
    },
    displayTags() {
      this.$emit("update:filteringTags", this.filteringByTags.length > 0);

      return this.displayFiltering("tags");
    },
    displayLocations() {
      this.$emit(
        "update:filteringLocations",
        this.filteringByLocations.length > 0
      );

      return this.displayFiltering("locations");
    },
    displayMedia() {
      this.$emit("update:filteringMedia", this.filteringByMedia.length > 0);

      return this.displayFiltering("media");
    },
    displayPlatforms() {
      this.$emit(
        "update:filteringPlatforms",
        this.filteringByPlatforms.length > 0
      );

      return this.displayFiltering("platforms");
    },
    displayHandleOrSocialImpact() {
      this.$emit(
        "update:filteringHandleOrSocialImpact",
        this.filteringByHandleOrSocialImpact.length
      );

      return this.displayFiltering("handleOrSocialImpact");
    },
    displaySorting() {
      return this.displayFiltering("sorting");
    },
    showReactScoreFilters() {
      return !this.isSocialStream && this.$features.has("has_react_score");
    },
    displayReactScore() {
      this.$emit(
        "update:filteringReactScore",
        this.filteringByReactScore.length > 0
      );

      return this.displayFiltering("reactScore");
    },
    filteringByReactScore() {
      const filters = [];

      if (this.localFilters.filteringReactScore()) {
        if (this.localFilters.reactScoreAverageSelected)
          filters.push(this.$t("expandable_filters.react_score_average"));
        else filters.push(this.$t("expandable_filters.risk_categories"));
      }

      return filters;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.filters) return;

        this.refreshMedia = true;
        this.localFilters = this.filters.clone();

        if (!this.localFilters.range) {
          this.localFilters.range = this.dateRange;
        }
      },
    },
    "localFilters.includedMentionsEnabled": {
      handler() {
        this.$emit("update:filters", this.localFilters);
      },
    },
  },
  mounted() {
    if (this.filters) {
      Object.assign(this.localFilters, this.filters.requestFilters());
    } else {
      this.localFilters.media = this.defaultEnabledMedia;
    }

    this.$emit("update:filters", this.localFilters);
  },
  methods: {
    toggleLocation(id) {
      if (this.localFilters.locations.includes(id)) {
        this.localFilters.locations = this.localFilters.locations.filter(
          (location) => location !== id
        );
      } else {
        this.localFilters.locations = [...this.localFilters.locations, id];
      }
    },
    displayFiltering(tab) {
      if (this.activeTab === tab && !this.showMultiple) {
        return "";
      }

      let filters = [];
      let prefix = "";

      switch (tab) {
        case "content":
          filters = this.filteringByContent;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "reactScore":
          filters = this.filteringByReactScore;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "sources":
          filters = this.filteringBySources;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "tags":
          filters = this.filteringByTags;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "locations":
          filters = this.filteringByLocations;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "media":
          filters = this.filteringByMedia;
          prefix = this.$t("expandable_filters.exclude");
          break;
        case "platforms":
          filters = this.filteringByPlatforms;
          prefix = this.$t("expandable_filters.exclude");
          break;
        case "handleOrSocialImpact":
          filters = this.filteringByHandleOrSocialImpact;
          prefix = this.$t("expandable_filters.filtering_by");
          break;
        case "sorting":
          filters = this.sortingBy;
          prefix = this.$t("expandable_filters.sorting_by");
          break;
        default:
      }

      return filters.length ? `${prefix} ${toSentence(filters)}` : "";
    },
    searchParams() {
      return this.localFilters.requestFilters();
    },
    search() {
      const params = this.searchParams();
      this.$emit("update:filters", this.localFilters);
      this.$emit("search", params);
    },
    reset() {
      const filters = new StreamFilters(
        this.stream,
        this.filters.initialFilters
      );
      this.$emit("update:filters", filters);
      this.refreshMedia = true;
      this.refreshPlatforms = true;
      this.$emit("reset", filters);
    },
    apply() {
      this.debouncedSearch();
      this.$emit("apply", this.localFilters);
    },
    applySearch() {
      if (this.showApply) return;
      this.apply();
    },
    toggleActive(tab) {
      this.activeTab = this.activeTab === tab ? "" : tab;
    },
    displayActive(tab) {
      return this.showMultiple || this.activeTab === tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #fff;
  font-size: medium;

  & div {
    background-color: inherit;
  }
}

.active {
  background-color: #fff;
  color: $ds-denim-9;
}

.show-title-header {
  margin-top: calc($navbar-height + env(safe-area-inset-top));

  .header {
    max-height: calc(100vh - #{$navbar-height} - 42px);
    overflow: auto;
  }
}

.badge {
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid $ds-purple-5;
  font-size: 10px;
  vertical-align: top;
}

.apply-button {
  border-radius: 2px;
}

:deep(.q-item__section) {
  .text-caption {
    color: var(--s-button-bg-danger-active);
    letter-spacing: normal;
  }
}

:deep(.q-item__label) {
  font-weight: bold;
}

:deep(.q-item--dense) {
  padding: 2px;
}

:deep(.q-item) {
  .q-focus-helper {
    display: none;
  }
}
</style>
