<template>
  <div
    :class="{ active: active }"
    class="no-shrink"
  >
    <slot name="beforeTags" />

    <template v-if="hideInput">
      <span :class="errorClass">{{ hideInputLabel }}</span>
    </template>

    <template v-else>
      <Tag
        v-for="bookmarkStream in tags"
        :key="bookmarkStream.label"
        :color="labelColor(bookmarkStream)"
        :label="bookmarkStream.label"
        :palette="palette"
        class="q-mr-sm q-mb-sm"
        @remove="removeBookmarksStream(bookmarkStream)"
      />

      <div class="relative">
        <InputText
          v-model="query"
          new-design
          :debounce="300"
          @click="visible = true"
        />

        <div
          v-if="filteredBookmarkStreams.length && visible"
          ref="bookmarkList"
          class="autocomplete-list dropdown"
        >
          <div class="full-height scroll relative">
            <Checkbox
              v-if="!query"
              v-model="selectAll"
              :label="$t('expandable_filters.all_tags')"
              class="autocomplete-item bold"
              new-design
              :indeterminate="isIndeterminate"
              @update:model-value="toggleSelectAll"
            />

            <div
              v-for="(bookmarkStream, index) in filteredBookmarkStreams"
              :key="index"
              class="autocomplete-item"
            >
              <Checkbox
                v-model="bookmarkStream.selected"
                :label="bookmarkStream.label"
                new-design
                @update:model-value="
                  updateSelectedBookmarkStreams(bookmarkStream)
                "
              >
                <template #label>
                  <Tag
                    :color="labelColor(bookmarkStream)"
                    :label="bookmarkStream.label"
                    :palette="palette"
                    hide-remove-button
                    rounded
                  />
                </template>
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core/index";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";

import { InputText, Tag } from "shared/components/base";
import {
  defaultBookmarkPalette,
  streamTypes,
  tagPalette,
} from "shared/constants";
import { useStreamsStore } from "shared/stores/streams";
import type { Nullable } from "shared/types";
import { Stream } from "shared/types";

export interface Bookmark {
  id: number;
  label: string;
  color: Nullable<string>;
  selected: boolean;
}

export interface DropdownTagsProps {
  active?: boolean;
  stream: Stream;
  tags: Bookmark[];
  hideInput?: boolean;
  hideInputLabel?: string;
  errorClass?: string;
}

const props = withDefaults(defineProps<DropdownTagsProps>(), {
  tags: () => [],
  hideInputLabel: "",
  errorClass: "",
});

const bookmarkList = ref<HTMLDivElement | null>(null);
const query = ref<string>("");
const selectAll = ref<boolean>(false);
const visible = ref<boolean>(false);

const streamsStore = useStreamsStore();
const { mediaBookmarkStreams, socialBookmarkStreams } =
  storeToRefs(streamsStore);

const palette = computed(() => ({
  ...tagPalette,
  ...defaultBookmarkPalette,
}));

const emit = defineEmits<{
  "update:tags": [{}];
  search: [];
}>();

onMounted(() => {
  onClickOutside(bookmarkList, () => {
    visible.value = false;
  });
});

const bookmarkStreams = computed(() => {
  if (props.stream.type === streamTypes.socialStream) {
    return socialBookmarkStreams.value;
  }

  return mediaBookmarkStreams.value;
});

const filteredBookmarkStreams = computed(() => {
  const bookmarkStreamsFiltered = bookmarkStreams.value.filter(
    (bookmark: Bookmark) =>
      encodeURI(bookmark.label).match(new RegExp(encodeURI(query.value), "i"))
  );

  const selectedBookmarkStreamIds = props.tags.map((bookmark) => bookmark.id);

  return bookmarkStreamsFiltered.map((bookmark: Bookmark) => ({
    id: bookmark.id,
    label: bookmark.label,
    color: bookmark.color,
    selected: selectedBookmarkStreamIds.includes(bookmark.id),
  }));
});

const labelColor = (bookmark: Bookmark) =>
  bookmark.color || Object.keys(defaultBookmarkPalette)[0];

const addBookmarkStream = (bookmarkStream: Bookmark) => {
  const tags = [...props.tags, bookmarkStream];

  emit("update:tags", tags);
  emit("search");
};

const isIndeterminate = computed(
  () => props.tags.length > 0 && !selectAll.value
);

const removeBookmarksStream = (bookmarkStream: Bookmark) => {
  const tags = props.tags.filter(
    (selectedBookmarkStream) => bookmarkStream.id !== selectedBookmarkStream.id
  );
  emit("update:tags", tags);
  emit("search");
};

const updateSelectedBookmarkStreams = (bookmarkStream: Bookmark) => {
  const existingTag = props.tags.find(
    (selectedBookmarkStream) => selectedBookmarkStream.id === bookmarkStream.id
  );

  if (existingTag) {
    removeBookmarksStream(bookmarkStream);
  } else {
    addBookmarkStream(bookmarkStream);
  }
};

const toggleSelectAll = () => {
  let selectedBookmarksStreams: Bookmark[] = [];

  if (selectAll.value) {
    selectedBookmarksStreams = bookmarkStreams.value.map(
      (bookmark: Bookmark) => ({
        id: bookmark.id,
        label: bookmark.label,
        color: bookmark.color,
      })
    );
  }

  emit("update:tags", selectedBookmarksStreams);
  emit("search");
};

function resetFilter() {
  if (visible.value) return;

  query.value = "";
}

function updateSelectAll() {
  selectAll.value = props.tags.length === bookmarkStreams.value.length;
}

watch(visible, () => resetFilter());
watch(
  () => props.tags,
  () => updateSelectAll()
);
</script>

<style lang="scss" scoped>
.active {
  background-color: $white;
}

.dropdown {
  overflow-y: scroll;
  position: relative;
}

.autocomplete-list {
  max-height: 300px;
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 9999;
  box-shadow: 4px 7px 8px 4px #ccc;

  .autocomplete-item {
    cursor: pointer;
    margin-left: 16px;
  }
}
</style>
