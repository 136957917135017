interface Shortcuts {
  enabled: boolean;
  [key: string]: boolean | (() => void)[];
}

interface ShortcutConfig {
  key: string;
  callback: () => void;
}

export const shortcuts: Shortcuts = {
  enabled: true,
};

function validShortcutFocus(target: HTMLElement): boolean {
  const invalidTargetsType = ["text", "textarea", "search", "number"];
  const invalidElements = ["input", "textarea", "select", "button"];
  const invalidClassNames = ["ProseMirror", "ql-editor"];
  const invalidAttributes = ["contenteditable"];
  const invalidIds: string[] = [];

  return (
    !invalidElements.includes(target.tagName.toLowerCase()) &&
    !invalidTargetsType.includes((target as HTMLInputElement).type) &&
    !invalidAttributes.some((attribute) => target.getAttribute(attribute)) &&
    !invalidClassNames.some((invalidClassName) =>
      target.classList.contains(invalidClassName)
    ) &&
    !invalidIds.includes(target.id)
  );
}

function getShortcutKey(event: KeyboardEvent): string {
  const { ctrlKey, altKey, shiftKey, metaKey, key } = event;

  const shortcutKeys: string[] = [];

  if (ctrlKey) shortcutKeys.push("Control");
  if (altKey) shortcutKeys.push("Alt");
  if (shiftKey) shortcutKeys.push("Shift");
  if (metaKey) shortcutKeys.push("Meta");

  if (!shortcutKeys.some((shortcutKey) => shortcutKey === key)) {
    shortcutKeys.push(key);
  }

  return shortcutKeys.join("+");
}

function runCallbacks(event: KeyboardEvent): void {
  if (!shortcuts.enabled) return;

  const shortcutKey = getShortcutKey(event);

  if (
    shortcuts[shortcutKey] &&
    validShortcutFocus(event.target as HTMLElement)
  ) {
    event.preventDefault();
    (shortcuts[shortcutKey] as (() => void)[]).forEach((callback) => {
      if (callback) callback.call(event);
    });
  }
}

export const addKeydownListener = (): void => {
  window.addEventListener("keydown", (event) => runCallbacks(event));
};

export const addShortcut = ({ key, callback }: ShortcutConfig): void => {
  if (!shortcuts[key]) {
    shortcuts[key] = [callback];
  } else {
    (shortcuts[key] as (() => void)[]).push(callback);
  }
};

export const addShortcutList = (shortcutList: ShortcutConfig[]): void => {
  shortcutList.forEach((shortcut) => addShortcut(shortcut));
};

export const removeShortcut = (shortcutKey: string): void => {
  (shortcuts[shortcutKey] as (() => void)[]).pop();
};

export const removeShortcutList = (shortcutList: ShortcutConfig[]): void => {
  shortcutList.forEach((shortcut) => removeShortcut(shortcut.key));
};

export const disableShortcuts = (): void => {
  shortcuts.enabled = false;
};

export const enableShortcuts = (): void => {
  shortcuts.enabled = true;
};
