import { type StoreInstance } from "@quasar/app-vite";
import axios, {
  type Axios,
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestHeaders,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import axiosRetry from "axios-retry";
import { type Router } from "vue-router";

import { v1BaseUrl } from "shared/constants";
import { updateUserToken } from "shared/helpers/authorization";
import {
  connectionError,
  forbiddenError,
  serverError,
  unauthorizedError,
} from "shared/helpers/errors";
import random from "shared/helpers/random";
import transformKeys from "shared/helpers/transformKeys";
import StorageService from "shared/services/StorageService";

declare module "axios" {
  export interface Axios {
    cacheKiller?: number;
  }

  export interface AxiosRequestConfig {
    handleErrors?: boolean;
    noAuth?: boolean;
    transformKeys?: boolean;
  }
  export interface InternalAxiosRequestConfig<D = any>
    extends AxiosRequestConfig<D> {
    headers: AxiosRequestHeaders;
  }
}

export type CreateAxiosInstanceError = AxiosError & {
  response?: {
    status: number;
    data: {
      error?: boolean;
      message: string;
      two_factor_auth_carrier?: string;
      two_factor_required?: boolean;
    };
  };
  config?: {
    handleErrors: boolean;
  };
};

interface CreateAxiosInstance {
  router?: Router;
  store?: StoreInstance;
  userStore?: any;
  baseURL?: string;
  error?: CreateAxiosInstanceError;
}

export function updateBaseURL(axiosInstance: Axios, url: string) {
  // eslint-disable-next-line no-param-reassign
  axiosInstance.defaults.baseURL = /^production_[a-z]{2}$/.test(
    import.meta.env.VITE_ENV
  )
    ? `${url}/v1`
    : v1BaseUrl;
}

export async function refreshTokenResponseInterceptor(
  response: AxiosResponse
): Promise<AxiosResponse> {
  if (response.headers.authorization) {
    await updateUserToken(response.headers.authorization);
  }

  return response;
}

export function serializeJsonKeyInterceptor(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  if (config.transformKeys) {
    if (config.params) {
      Object.assign(config, {
        params: transformKeys(config.params, "snakeCase"),
      });
    }

    if (config.data) {
      Object.assign(config, {
        data: transformKeys(config.data, "snakeCase"),
      });
    }
  }

  return config;
}

export function deserializeJsonKeyInterceptor(
  response: AxiosResponse
): AxiosResponse {
  if (response.config.transformKeys) {
    response.data = transformKeys(response.data);
  }

  return response;
}

export async function authTokenRequestInteceptor(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  const requestConfig = { ...config };

  requestConfig.handleErrors = config.handleErrors
    ? config.handleErrors
    : false;

  const headers = { ...config.headers } as AxiosRequestHeaders;

  if (!config.noAuth) {
    const token = await StorageService.get("token");
    headers.Authorization = `Bearer ${token}`;
  }

  requestConfig.headers = headers;

  return requestConfig;
}

export function resolveError({ error, router, store }: CreateAxiosInstance) {
  if (error?.response?.status) {
    switch (error.response.status) {
      case 422: // Got validation error
        return Promise.reject(error);
      default: // When any other error is received...
        return serverError({ error, router, store });
    }
  } else {
    return connectionError({ error, router, store });
  }
}

export async function handleErrors({
  error,
  router,
  store,
  userStore,
}: CreateAxiosInstance): Promise<CreateAxiosInstance> {
  if (
    [401].includes(Number(error?.response?.status)) &&
    error?.response?.data?.message !== "sudo access required"
  ) {
    unauthorizedError({ error, router, store, userStore });

    if (error?.response?.data?.two_factor_required) {
      return Promise.reject(error);
    }

    return new Promise(() => {});
  }

  if (Number(error?.response?.status) === 403) {
    forbiddenError({ error, router, store, userStore });

    return new Promise(() => {});
  }

  if (error?.config?.handleErrors === true) {
    resolveError({ error, router, store });

    return new Promise(() => {});
  }

  return Promise.reject(error);
}

export default ({ router, store, userStore, baseURL }: CreateAxiosInstance) => {
  const axiosInstance: Axios = axios.create({
    baseURL,
    timeout: 120000,
  });

  let errorHandler = (
    error: CreateAxiosInstanceError
  ): Promise<AxiosError | {}> => Promise.reject(error);

  // use full error handlers if router and store are injected by quasar
  if (router && store) {
    errorHandler = (
      error: CreateAxiosInstanceError
    ): Promise<CreateAxiosInstance> =>
      handleErrors({
        error,
        router,
        store,
        userStore,
      });
  }

  // Add stored token, if exists, and default handleErrors to true, to all requests.
  axiosInstance.interceptors.request.use(
    authTokenRequestInteceptor,
    errorHandler
  );

  // json key transformations
  axiosInstance.interceptors.request.use(
    serializeJsonKeyInterceptor,
    errorHandler
  );

  axiosInstance.interceptors.response.use(
    deserializeJsonKeyInterceptor,
    errorHandler
  );

  // Update refresh token if exists
  axiosInstance.interceptors.response.use(
    refreshTokenResponseInterceptor,
    errorHandler
  );

  // set a random number to use to break caches if necessary
  axiosInstance.cacheKiller = axiosInstance.cacheKiller || random();

  // retry requests when failed due to network issues
  axiosRetry(axiosInstance as AxiosInstance, { retries: 3 });

  return axiosInstance;
};
