<template>
  <div
    v-if="showSyndications"
    class="publishers-list"
    :class="{ 'q-pa-md publishers-list-dropdown': isDropdown }"
  >
    <a
      v-for="(syndicated, key) in syndications"
      :key="key"
      :class="{
        'cursor-pointer': !isRadio,
        'no-shrink': !isDropdown,
        block: isDropdown,
      }"
      @click.stop="syndicationClicked(syndicated)"
    >
      <div
        class="syndication-matches-stream"
        :class="{ 'syndication-matches-stream-dropdown': isDropdown }"
      >
        <span v-if="syndicationsMatchingStream[syndicated.id]">
          ✓
          <StreemTooltip>
            {{ getLocaleText("syndications.syndicated_item_tooltip") }}
          </StreemTooltip>
        </span>
      </div>
      <span class="bold">
        {{ syndicated.source_name }}
      </span>
      <span class="muted float-right syndication-time">
        {{ formatSyndicatedDate(syndicated) }}
      </span>
    </a>
  </div>
  <div
    v-else-if="loading"
    class="publishers-list"
    :class="{ 'q-pa-md publishers-list-dropdown': isDropdown }"
  >
    <QSpinner
      :size="30"
      class="full-width q-my-sm"
    />
  </div>
</template>

<script setup lang="ts">
import { Notify } from "quasar";
import { computed, ref, watch } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import StreemTooltip from "shared/components/base/StreemTooltip.vue";
import streamFiltering from "shared/composables/streamFiltering";
import { dateInTimeZone, parseDate, shortTimezone } from "shared/helpers/date";
import { sortedSyndications } from "shared/helpers/mentions";
import MatchingSyndications from "shared/services/fetching/matchingSyndications";
import { Stream } from "shared/types";
import {
  MentionWithSyndication,
  Syndication,
  TvCaptionSyndication,
} from "shared/types/mentions";
import { isTvOrRadioSyndication } from "shared/types/mentions/guards";

export type SyndicationProps = {
  expanded?: boolean;
  isDropdown?: boolean;
  mention: MentionWithSyndication;
  stream?: Stream;
};

const props = defineProps<SyndicationProps>();

const emit = defineEmits<{
  (e: "syndication-clicked", syndication: Syndication): void;
}>();

const { filters } = props.stream
  ? streamFiltering(props.stream)
  : { filters: null };

const syndicationsMatchingStream = ref<Record<string, boolean>>({});
const loading = ref(false);

const isTv = computed(() =>
  ["tv_super", "tv_caption", "tv_logo_appearance"].includes(props.mention.type)
);
const isRadio = computed(() => props.mention.type === "radio_clip");

const syndications = computed(() => sortedSyndications(props.mention));

const showSyndications = computed(
  () => props.expanded && props.mention.syndications.length && !loading.value
);

const hasPlayerUrl = (
  syndication: Syndication
): syndication is TvCaptionSyndication =>
  (syndication as TvCaptionSyndication).tveyes_player_url !== undefined;

const formatSyndicatedDate = (syndicated: Syndication) => {
  const timestamp = isTvOrRadioSyndication(syndicated)
    ? syndicated.start_time
    : syndicated.published_at;
  const timezone = shortTimezone(
    parseDate(timestamp),
    syndicated.source.time_zone
  );
  const timeString = dateInTimeZone(
    timestamp,
    "h:mmaaa",
    syndicated.source.time_zone
  );

  return `${timeString} ${timezone}`;
};

const syndicationClicked = (syndication: Syndication) => {
  if (isTv.value) {
    const mergedObject = {
      ...syndication,
      excerpts: props.mention.excerpts,
      type: props.mention.type,
    };
    emit("syndication-clicked", mergedObject);
  } else if (!isRadio.value || hasPlayerUrl(syndication)) {
    emit("syndication-clicked", {
      ...syndication,
      type: props.mention.type,
    });
  }
};

const loadSyndicationMatches = async () => {
  loading.value = true;

  try {
    if (props?.stream?.id) {
      const options = filters.value ? filters.value.requestFilters() : {};
      const response = await MatchingSyndications.get({
        stream: props.stream,
        mention: props.mention,
        options,
      });
      syndicationsMatchingStream.value = response.syndications_matching_stream;
    }
  } catch (error) {
    Notify.create(getLocaleText("syndications.unable_to_load_syndications"));
  } finally {
    loading.value = false;
  }
};

watch(
  () => props.expanded,
  (newValue) => {
    if (newValue) {
      loadSyndicationMatches();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.publishers-list-dropdown {
  text-align: left;
  width: 300px;
}

.syndication-matches-stream-dropdown {
  display: inline-block;
  width: 0.8em;
}

.muted {
  font-size: 80%;
  opacity: 0.5;
}
</style>
