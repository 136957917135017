<template>
  <div
    :class="{ active: active }"
    class="no-shrink"
  >
    <div :class="spanClass">
      <QToggle
        v-model="handleVerifiedEnabled"
        dense
        :color="toggleColor"
        :label="$t('social_impact_expandable.verified')"
        class="full-width justify-between"
        left-label
      />
    </div>

    <div :class="spanClass">
      <QToggle
        v-model="handleMinimumFollowerCountEnabled"
        dense
        :color="toggleColor"
        class="full-width justify-between"
        :label="$t('social_impact_expandable.minimum_followers')"
        left-label
      />
    </div>
    <InputSelect
      v-if="minimumFollowerCountEnabled"
      v-model="handleMinimumFollowerCount"
      :options="minimumFollowerOptions"
      option-label="label"
      option-prop="value"
      emit-value
      new-design
      close-on-select
      class="q-mt-sm"
    />

    <slot name="after" />
  </div>
</template>

<script>
import { InputSelect } from "shared/components/base";

const MINIMUM_FOLLOWER_OPTIONS = [500, 1000, 5000, 10000, 25000, 50000, 100000];

export default {
  name: "SocialImpactExpandable",
  components: {
    InputSelect,
  },
  props: {
    active: {
      type: Boolean,
    },
    minimumFollowerCount: {
      type: Number,
      default: 500,
    },
    minimumFollowerCountEnabled: {
      type: Boolean,
    },
    verifiedEnabled: {
      type: Boolean,
    },
    toggleColor: {
      type: String,
      default: "",
    },
    spanClass: {
      type: String,
      default: "",
    },
  },
  emits: [
    "update:minimumFollowerCountEnabled",
    "update:minimumFollowerCount",
    "search",
    "update:verifiedEnabled",
  ],
  computed: {
    minimumFollowerOptions() {
      return MINIMUM_FOLLOWER_OPTIONS.map((option) => ({
        label: option.toLocaleString(),
        value: option,
      }));
    },
    handleMinimumFollowerCountEnabled: {
      get() {
        return this.minimumFollowerCountEnabled;
      },
      set(value) {
        this.$emit("update:minimumFollowerCountEnabled", value);

        if (value) this.trackMinimumFollowerCount(this.minimumFollowerCount);
      },
    },
    handleMinimumFollowerCount: {
      get() {
        return this.minimumFollowerCount;
      },
      set(value) {
        this.$emit("update:minimumFollowerCount", value);
        this.$emit("search");

        this.trackMinimumFollowerCount(value);
      },
    },
    handleVerifiedEnabled: {
      get() {
        return this.verifiedEnabled;
      },
      set(value) {
        if (value) this.$track("Filtered Social Stream by Verified Account");

        this.$emit("update:verifiedEnabled", value);
      },
    },
  },
  methods: {
    trackMinimumFollowerCount(value) {
      this.$track("Filtered Social Stream by Minimum Follower Count", {
        option: value,
      });
    },
  },
};
</script>
