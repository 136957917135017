import type {
  Axios,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenStatic,
} from "axios";
import axios from "axios";

import { v1BaseUrl as baseURL } from "shared/constants";
import createAxiosInstance from "shared/helpers/createAxiosInstance";

type HttpVerb = "get" | "put" | "post" | "delete";
type ResourceIdentifier = number | string;

const baseInstance = createAxiosInstance({ baseURL });

export const ApiClientError = axios.AxiosError;

export default class ApiClient {
  resource: string;

  client: Axios;

  constructor(resource: string, client = baseInstance) {
    this.resource = resource;
    this.client = client;
  }

  static isApiError(error: unknown): error is AxiosError {
    return error instanceof ApiClientError;
  }

  get<T = any>(
    url: string,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.run("get", url, options);
  }

  put<T = any>(
    url: string,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.run("put", url, options);
  }

  post<T = any>(
    url: string,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.run("post", url, options);
  }

  delete<T = any>(
    url: string,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.run("delete", url, options);
  }

  index<T = any>(options = <AxiosRequestConfig>{}): Promise<AxiosResponse<T>> {
    return this.get("", options);
  }

  show<T = any>(
    id: ResourceIdentifier,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.get(`/${id}`, options);
  }

  create<T = any>(options = <AxiosRequestConfig>{}): Promise<AxiosResponse<T>> {
    return this.post("/", options);
  }

  update<T = any>(
    id: ResourceIdentifier,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.put(`/${id}`, options);
  }

  destroy<T = any>(
    id: ResourceIdentifier,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    return this.delete(`/${id}`, options);
  }

  get cancelToken(): CancelTokenStatic {
    return axios.CancelToken;
  }

  isCancel(error: AxiosError): boolean {
    return axios.isCancel(error);
  }

  private run<T = any>(
    method: HttpVerb,
    url: string,
    options = <AxiosRequestConfig>{}
  ): Promise<AxiosResponse<T>> {
    const requestUrl = this.buildUrl(url);
    const request = this.buildRequest(method, requestUrl, options);

    return this.client.request(request);
  }

  private buildUrl(url: string): string {
    return url.match(/http|ftp/g) ? url : `${this.resource}${url}`;
  }

  private buildRequest(
    method: HttpVerb,
    url: string,
    options = <AxiosRequestConfig>{}
  ): AxiosRequestConfig {
    const { cacheKiller } = baseInstance;
    const { params, ...axiosParams } = options;

    const request: AxiosRequestConfig = {
      method,
      url,
    };

    if (method === "get") {
      request.params = { ...params, cacheKiller };
    } else {
      request.data = params;
      request.params = { cacheKiller };
    }

    return {
      ...request,
      ...axiosParams,
    };
  }
}
