import { Notify } from "quasar";

import { isAdminMode } from "shared/constants";
import { getSource } from "shared/helpers/media";
import { useOrganisationFeaturesStore } from "shared/stores/organisationFeatures";
import { useUserStore } from "shared/stores/user";

import activities from "./activities";

const BETA_FEATURES = [
  "has_spiked_notifications",
  "has_push_to_team_notifications_toggle",
  "has_sentiment_rating_mode",
  "has_signals_notification",
  "has_most_read_sources",
  "has_instant_insights_top_quoted",
  "has_social_platform",
  "has_social_engage",
  "has_outreach_2",
];

function getFeatures() {
  return useOrganisationFeaturesStore().organisationFeatures;
}

function isAdminUser() {
  return useUserStore().isAdminUser || isAdminMode;
}

export default {
  has(feature) {
    let features = null;

    if (isAdminMode) {
      features = Object.keys(
        useOrganisationFeaturesStore().organisationFeatures
      );
      if (!features.length) return true;
    } else {
      features = useUserStore().organisation.features;
    }

    const featureSet = [
      ...(features || []),
      ...(useUserStore().betaEnabled ? BETA_FEATURES : []),
    ];

    return featureSet.includes(feature);
  },

  hasAny(features = []) {
    return features.some((feature) => this.has(feature));
  },

  hasAll(features = []) {
    return features.every((feature) => this.has(feature));
  },

  getUsedCredits(feature) {
    return getFeatures()[feature].used_credits;
  },

  getTotalUsedCredits(featureId) {
    return getFeatures()[featureId].total_used_credits;
  },

  getFeatureUnitKeypath(feature) {
    return feature === "audio_transcript" ? "services.features.minute" : "";
  },

  getContractAllowance(featureId) {
    return getFeatures()[featureId].contract_allowance;
  },

  getUsagePercentage(featureId) {
    const feature = getFeatures()[featureId];

    if (feature.contract_allowance === null) return 0;
    if (feature.total_used_credits > feature.contract_allowance) return 100;

    const percentage =
      (feature.total_used_credits / feature.contract_allowance) * 100;

    if (percentage < 1) {
      return percentage.toFixed(2);
    }

    return Math.round(percentage, 2);
  },

  getCredits(feature) {
    return getFeatures()[feature].credits;
  },

  getCost(feature) {
    return getFeatures()[feature].cost;
  },

  hasCredits(feature) {
    const { credits } = getFeatures()[feature];

    // if credits is null, it's unlimited
    if (credits === null || credits > 0) {
      return true;
    }

    return false;
  },

  noMoreMonthlyCredits(feature) {
    const features = getFeatures();

    return (
      features[feature].credits !== null &&
      features[feature].used_credits >= features[feature].credits
    );
  },

  reachedContractAllowance(feature) {
    const features = getFeatures();

    return (
      features[feature].credits !== null &&
      features[feature].total_used_credits >=
        features[feature].contract_allowance
    );
  },

  reachedWarning(source) {
    const features = getFeatures();

    const warningFeature = source.viewWarningFeatureFlag;
    const allowanceFeature = source.featureAllowanceFlag;

    if (features[warningFeature]) {
      return (
        features[allowanceFeature].credits !== null &&
        features[allowanceFeature].used_credits >
          features[warningFeature].credits
      );
    }

    return false;
  },

  featureUnavailable(featureName) {
    Notify.create({
      type: "positive",
      html: true,
      message: `<h3>${featureName} Unavailable</h3><span>This feature is not available for your organisation.<br>Please get in touch with your Account Manager to upgrade your subscription.</span>`,
    });
  },

  alwaysWarn(mention) {
    const source = getSource(mention.type);

    return (
      source.viewPopupShown === "always" &&
      this.has(source.viewWarningFeatureFlag) &&
      (!this.hasCredits(source.featureAllowanceFlag) ||
        this.reachedWarning(source))
    );
  },

  async requiresActivityRecord(mention) {
    const source = getSource(mention.type);

    if (!this.has(source.viewFeatureFlag)) {
      this.featureUnavailable(source.featureName);

      return false;
    }

    if (isAdminUser()) {
      return false;
    }

    let alreadyRecordedActivity = false;

    try {
      alreadyRecordedActivity =
        (await activities.getActivitiesCount(mention)) > 0;

      if (!alreadyRecordedActivity) {
        const refreshCreditsPromises = [
          useOrganisationFeaturesStore().refreshUsedCredits({
            organisationId: useUserStore().organisation.id,
            feature: source.featureAllowanceFlag,
          }),
        ];

        if (source.viewWarningFeatureFlag) {
          refreshCreditsPromises.push(
            useOrganisationFeaturesStore().refreshUsedCredits({
              organisationId: useUserStore().organisation.id,
              feature: source.viewWarningFeatureFlag,
              handleErrors: false,
            })
          );
        }

        await Promise.all(refreshCreditsPromises);
      }
    } catch (error) {
      return false;
    }

    if (!alreadyRecordedActivity && this.alwaysWarn(mention)) {
      return true;
    }

    return false;
  },
};
